import { ReactGrid, Column, Row, CellChange, NumberCell } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import React, { useEffect, useState } from "react";
import { db } from '../../firebase/firebase';
import { addDoc, collection, serverTimestamp, onSnapshot, query, where, orderBy, limit, updateDoc, doc } from "firebase/firestore";
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

// Display Page
const DisplayInactivePage = () => {
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [grids, setGrids] = useState({});
  const [prices, setPrices] = useState({});
  const [supplyLevels, setSupplyLevels] = useState({});
  const [demandLevels, setDemandLevels] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    const symbols =['MES', 'MNQ', 'M2K', 'MYM', 'MCL', 'MGC', 'MBT', 'M6A', 'M6E', 'MHG', 'MNG', 'SIL'];
    const unsubscribes = symbols.map((symbol) => {
      const entriesCollectionRef = collection(db, `grid/${symbol}/invalid_demand`);
      const recentActiveRef = query(entriesCollectionRef, orderBy("high", "desc"), limit(5));
      return onSnapshot(recentActiveRef, (snapshot) => {
        const newGrid = snapshot.docs.map((doc) => {
        let data = doc.data();
        return {
          id: doc.id,
          high: data.high,
          low: data.low,
          timeFrame: data.timeFrame,
          score1: data.score1,
          score2: data.score2,
          scoreLink: data.scoreLink,
          chartLink: data.chartLink,
          symbol
        };
          return {
            id: doc.id,
            high: data.high,
            low: data.low,
            timeFrame: data.timeFrame,
            score1: data.score1,
            score2: data.score2,
            symbol
          };
        })[0];
        setGrids((prevGrids) => ({ ...prevGrids, [symbol]: newGrid }));
      });
    });

    const priceUnsubscribes = symbols.map((symbol) => {
      const priceDocRef = doc(db, `price/${symbol}`);
      return onSnapshot(priceDocRef, (doc) => {
        console.log('Price:', doc.data());
        if (doc.exists()) {
          setPrices((prevPrices) => ({ ...prevPrices, [symbol]: doc.data().price }));
        }
      });
    });

    const supplyLevelUnsubscribes = symbols.map((symbol) => {
      const supplyCollectionRef = collection(db, `grid/${symbol}/invalid_supply`);
      const supplyQuery = query(supplyCollectionRef, orderBy("low", "asc"));
      return onSnapshot(supplyQuery, (snapshot) => {
        const levels = snapshot.docs.map((doc) => doc.data());
        setSupplyLevels((prevLevels) => ({ ...prevLevels, [symbol]: levels }));
      });
    });

    const demandLevelUnsubscribes = symbols.map((symbol) => {
      const demandCollectionRef = collection(db, `grid/${symbol}/invalid_demand`);
      const demandQuery = query(demandCollectionRef, orderBy("low", "asc"));
      return onSnapshot(demandQuery, (snapshot) => {
        const levels = snapshot.docs.map((doc) => doc.data());
        setDemandLevels((prevLevels) => ({ ...prevLevels, [symbol]: levels }));
      });
    });

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
      priceUnsubscribes.forEach((unsubscribe) => unsubscribe());
      supplyLevelUnsubscribes.forEach((unsubscribe) => unsubscribe());
      demandLevelUnsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, []);

  const handleGridClick = (levelData) => {
    console.log("Level Data:", levelData);
    setSelectedLevel({ ...levelData, type: levelData.type || (levelData.high && levelData.low ? 'supply' : 'demand') });
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {Object.values(grids).map((grid) => (
          grid && (
            <div key={grid.id}>
              <span><h2> {grid.symbol}</h2></span>
              {demandLevels[grid.symbol]?.map((level, index) => (
                <Button key={index} color="success" onClick={() => handleGridClick(level)}>
                  {level.high}  <br />  {level.low}
                </Button>
              ))}
              {prices[grid.symbol] ?? 'N/A'}
              {supplyLevels[grid.symbol]?.map((level, index) => (
                <Button key={index} color="warning" onClick={() => handleGridClick(level)}>
                  {level.high}  <br />  {level.low}
                </Button>
              ))}
            </div>
          )
        ))}
      </div>
      {selectedLevel && (
        <div style={{ marginLeft: '20px', padding: '10px', border: '1px solid #ccc', backgroundColor: selectedLevel.type === 'supply' ? 'warning' : 'success' }}>
          <h3>Selected Level Details</h3>
          <p><strong>High:</strong> {selectedLevel.high}</p>
          <p><strong>Low:</strong> {selectedLevel.low}</p>
          <p><strong>Time Frame:</strong> {selectedLevel.timeFrame}</p>
          <p><strong>Score 1:</strong> {selectedLevel.score1}</p>
          <p><strong>Score 2:</strong> {selectedLevel.score2}</p>
          <p><strong>Symbol:</strong> {selectedLevel.symbol}</p>
          {selectedLevel.scoreLink && <p><strong>Score Link:</strong> <a href={selectedLevel.scoreLink} target="_blank" rel="noopener noreferrer">{selectedLevel.scoreLink}</a></p>}
          {selectedLevel.chartLink && <p><strong>Chart Link:</strong> <a href={selectedLevel.chartLink} target="_blank" rel="noopener noreferrer">{selectedLevel.chartLink}</a></p>}
        </div>
      )}
    </div>
  );
};

export default DisplayInactivePage;