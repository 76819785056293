import React, { useEffect, useState } from "react";
import { db } from '../../firebase/firebase';
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { Button, Input, FormGroup, Label } from "reactstrap";

const DailyATRPage = () => {
  const symbols = ['MES', 'MNQ', 'M2K', 'MYM', 'MCL', 'MGC', 'MBT', 'M6A', 'M6E', 'MHG', 'MNG', 'QI'];
  const [atrValues, setAtrValues] = useState({});

  useEffect(() => {
    const fetchATRValues = async () => {
      const fetchedValues = {};
      for (const symbol of symbols) {
        const docRef = doc(db, "daily_atr", symbol);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          fetchedValues[symbol] = docSnap.data().atr;
        } else {
          fetchedValues[symbol] = "";
        }
      }
      setAtrValues(fetchedValues);
    };

    fetchATRValues();
  }, []);

  const handleATRChange = (symbol, value) => {
    setAtrValues((prevValues) => ({
      ...prevValues,
      [symbol]: value,
    }));
  };

  const saveATRValues = async () => {
    try {
      for (const symbol of symbols) {
        const atrValue = parseFloat(atrValues[symbol]);
        if (!isNaN(atrValue)) {
          const docRef = doc(db, "daily_atr", symbol);
          await setDoc(docRef, { atr: atrValue });
        }
      }
      alert("ATR values saved successfully");
    } catch (error) {
      console.error("Error saving ATR values: ", error);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h2>Daily ATR Entry and Update</h2>
      {symbols.map((symbol) => (
        <FormGroup key={symbol} style={{ marginBottom: "10px", display: "flex", alignItems: "center" }}>
          <Label for={symbol} style={{ marginRight: "10px", width: "50px" }}>{symbol}</Label>
          <Input
            type="number"
            step="0.01"
            id={symbol}
            value={atrValues[symbol] !== undefined ? atrValues[symbol] : ""}
            onChange={(e) => handleATRChange(symbol, e.target.value)}
            style={{ width: "100px" }}
          />
        </FormGroup>
      ))}
      <Button color="primary" onClick={saveATRValues}>Save ATR Values</Button>
    </div>
  );
};

export default DailyATRPage;
