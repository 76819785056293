import React from 'react';
import { Link} from 'react-router-dom'
import {
    Badge,
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    UncontrolledTooltip
  } from "reactstrap";
const LandingPage = () =>{
    return (
        <main>
        <div className="position-relative">
          {/* shape Hero */}
      <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                 
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                        AlgoAdvance Interview Coding Copilot{" "}
                        <span> Your Expert Coding Interview Co-Pilot</span>
                      </h1>
                      <p className="lead text-white">
                       Ace the coding interview. Get the dream job you want, at the company you want.
                      </p>
                      <div>
                      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/rulcHMZ9drU?si=ka_Ejl6LqmIJHlY2&amp;controls=0&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                      </div>
                      <br></br>
                      <div className="btn-wrapper">
                      <a href="https://firebasestorage.googleapis.com/v0/b/wonmove-f6094.appspot.com/o/AlgoAdvance_demo.mp4?alt=media&token=daf2c850-1083-4db6-8b15-2d4561f7bad6" target="_blank" rel="noopener noreferrer">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                        >
                        
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span>
                          <span className="btn-inner--text">Watch Tutorial</span>
                        </Button>
                        </a>
                        <Link to={'/checkout'}>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-cloud-download-95" />
                          </span>
                          <span className="btn-inner--text">
                            Try for free
                          </span>
                        </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
                </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Weekly Plan
                          </h6>
                          <p className="description mt-3">
                          Get access to the AlgoAdvance Interview Assistant with a weekly subscription plan that fits your needs.
                          </p>
                          <div>
                          <Badge color="primary" pill className="mr-1">
                              Pay as you go
                            </Badge>
                            {/* <Badge color="primary" pill className="mr-1">
                              -commitment
                            </Badge> */}
                            {/* <Badge color="primary" pill className="mr-1">
                              credit card required
                            </Badge> */}
                            {/* <Badge color="primary" pill className="mr-1">
                              creative
                            </Badge> */}
                          </div>
                          <Link to={'/checkout'}>
                          <Button
                            className="mt-4"
                            color="primary" 
                          >
                            Learn more
                          </Button>
                          </Link>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            Monthly Plan
                          </h6>
                          <p className="description mt-3">
                            Get access to the AlgoAdvance Interview Assistant with a monthly subscription plan that fits your needs.
                          </p>
                          <div>
                            <Badge color="success" pill className="mr-1">
                              Includes 7 day free trial
                            </Badge>
                            {/* <Badge color="success" pill className="mr-1">
                              Real-time guidance
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              Pay as you go
                            </Badge> */}
                          </div>
                          <Link to={'/checkout'}>
                          <Button
                            className="mt-4"
                            color="success"
                          >
                            Learn more
                          </Button>
                          </Link>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Annual Plan
                          </h6>
                          <p className="description mt-3">
                            Get access to the AlgoAdvance Interview Assistant with an annual subscription plan that saves you money over the long run.
                          </p>
                          <div>
                            <Badge color="warning" pill className="mr-1">
                              Most bang for your buck
                            </Badge>
                            {/* <Badge color="warning" pill className="mr-1">
                              product
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              launch
                            </Badge> */}
                          </div>
                          <Link to={'/checkout'}>
                          <Button
                            className="mt-4"
                            color="warning"
                          >
                            Learn more
                          </Button>
                          </Link>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              </Container>
          </section>
          {/* <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("../../assets/img/theme/promo-1.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>
                    <h3>Advance your coding interview</h3>
                    <p>
                    Unlock your potential with the AlgoAdvance Interview Assistant, designed specifically to help you excel in technical coding interviews. Whether you're a seasoned developer or a new coder, our AI-driven assistant provides real-time guidance, problem-solving techniques, and expert advice tailored to your needs. With an intuitive interface, you can input coding challenges and receive optimized solutions, detailed explanations, and insights into best practices.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-settings-gear-65" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                             Adaptive Problem Solving
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-html5" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Efficiency at Your Fingertips</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                            Prep for Success
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section> */}
          {/* <section className="section bg-secondary">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("../../assets/img/theme/img-1-1200x1000.jpg")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                        Design System
                      </h4>
                      <p className="lead text-italic text-white">
                        The Arctic Ocean freezes every winter and much of the
                        sea-ice then thaws every summer, and that process will
                        continue whatever happens.
                      </p>
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                      <i className="ni ni-settings" />
                    </div>
                    <h3>Our customers</h3>
                    <p className="lead">
                      Don't let your uses guess by attaching tooltips and
                      popoves to any element. Just make sure you enable them
                      first via JavaScript.
                    </p>
                    <p>
                      The kit comes with three pre-built pages to help you get
                      started faster. You can change the text and images and
                      you're good to go.
                    </p>
                    <p>
                      The kit comes with three pre-built pages to help you get
                      started faster. You can change the text and images and
                      you're good to go.
                    </p>
                    <a
                      className="font-weight-bold text-warning mt-5"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      A beautiful UI Kit for impactful websites
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section> */}
          
          <section className="section pb-0 bg-gradient-warning">
            
              {/* <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("../../assets/img/ill/ill-2.svg")}
                    />
                  </div>
                </Col>
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 text-white">Modern Interface</h4>
                      <p className="text-white">
                        The Arctic Ocean freezes every winter and much of the
                        sea-ice then thaws every summer, and that process will
                        continue whatever.
                      </p>
                    </div>
                  </div>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Awesome Support
                          </h5>
                          <p>
                            The Arctic Ocean freezes every winter and much of
                            the sea-ice then thaws every summer, and that
                            process will continue whatever.
                          </p>
                          <a
                            className="text-success"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-active-40" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-warning">
                            Modular Components
                          </h5>
                          <p>
                            The Arctic Ocean freezes every winter and much of
                            the sea-ice then thaws every summer, and that
                            process will continue whatever.
                          </p>
                          <a
                            className="text-warning"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div> 
          </section>

          <section className="section section-lg">
          <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Success Stories/Testimonials</h2>
                  <p className="lead text-muted">
                    What our customers are saying about us.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("../../assets/img/theme/team-1-800x800.jpg")}
                      style={{ width: "200px" }} id="UncontrolledTooltip1"
                      />
                      <UncontrolledTooltip
      placement="top"
      target="UncontrolledTooltip1"
    >
      Stock photo and alias used to protect our customer's privacy.
    </UncontrolledTooltip>
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Ryan T</span>
                        <small className="h6 text-muted">Software Engineer at a FANG company</small>
                        <p>I am absolutely blown away by AlgoAdvance! 🚀 It’s like having a personal coding guru right at your fingertips. The way it breaks down complex problems is just phenomenal. Highly recommend to anyone serious about nailing their coding interviews!</p>
                      </h5>
                      {/* <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div> */}
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("../../assets/img/theme/team-2-800x800.jpg")}
                      style={{ width: "200px" }} id="UncontrolledTooltip2"
                      />
                      <UncontrolledTooltip
      placement="top"
      target="UncontrolledTooltip2"
    >
      Stock photo and alias used to protect our customer's privacy.
    </UncontrolledTooltip>
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Romina H</span>
                        <small className="h6 text-muted">
                          Software Engineer at a startup
                        </small>
                        <p>I was a bit skeptical at first, thinking, how much can a tool really help? But after using AlgoAdvance for just a week, I saw a significant improvement in my problem-solving skills. It's a game-changer for anyone in tech.</p>
                      </h5>
                      {/* <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div> */}
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("../../assets/img/theme/team-3-800x800.jpg")}
                      style={{ width: "200px" }} id="UncontrolledTooltip3"
                      />
                      <UncontrolledTooltip
      placement="top"
      target="UncontrolledTooltip3"
    >
      Stock photo and alias used to protect our customer's privacy.
    </UncontrolledTooltip>
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Alexander S</span>
                        <small className="h6 text-muted">Team Lead at a FANG company</small>
                        <p>Effective. Efficient. Essential.</p>
                      </h5>
                      {/* <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div> */}
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("../../assets/img/theme/team-4-800x800.jpg")}
                      style={{ width: "200px" }} id="UncontrolledTooltip4"
                      />
                      <UncontrolledTooltip
      placement="top"
      target="UncontrolledTooltip4"
    >
      Stock photo and alias used to protect our customer's privacy.
    </UncontrolledTooltip>
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Jane D</span>
                        <small className="h6 text-muted">Software Engineer at a Hedge Fund</small>
                        <p>Thanks to AlgoAdvance, I landed a job at my dream company! The interview prep was intense, but this tool made it so much more manageable. I can’t thank the team enough for creating something so helpful.</p>
                      </h5>
                      {/* <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div> */}
                    </div>
                  </div>
                </Col>
              </Row>
              </Container>
          </section>
          <section className="section section-lg pt-0">
          <Container>
              <Card className="bg-gradient-warning shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        What are you waiting for?{" "}
                      </h3>
                      <p className="lead text-white mt-3">
                        Time to ace that coding interview and land your dream job!
                      </p>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                    <Link to={'/register'}>
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        size="lg"
                      >
                      
                        Sign up now
                      </Button></Link>
                    </Col>
                  </Row>
                </div>
              </Card>
              </Container>
          </section>
          <section className="section section-lg bg-gradient-default">
              {/* <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Build something</h2>
                  <p className="lead text-white">
                    According to the National Oceanic and Atmospheric
                    Administration, Ted, Scambos, NSIDClead scentist, puts the
                    potentially record low maximum sea ice extent tihs year down
                    to low ice.
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-settings text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Building tools</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-ruler-pencil text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Grow your market</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-atom text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Launch time</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
              </Row> */}
            
            {/* SVG separator */}
            {/* <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div> */}
          </section>
        </div>
        
          

        </main>
        
    )
}

export default LandingPage;