import { useState, useRef, useEffect } from "react";
import { useAuth } from '../../contexts/authContext'
import { db , storage} from '../../firebase/firebase'
import { ref, uploadString, getDownloadURL, uploadBytes } from "firebase/storage";
import { addDoc, collection, serverTimestamp, getDocs, query, orderBy, limit, doc, updateDoc } from "firebase/firestore";

const mimeType = "audio/mp3";

const AudioRecorder = () => {
    const { currentUser, userLoggedIn } = useAuth();

	const [permission, setPermission] = useState(false);

	const mediaRecorder = useRef(null);

	const [recordingStatus, setRecordingStatus] = useState("inactive");

	const [stream, setStream] = useState(null);

	const [audio, setAudio] = useState(null);

	const [audioChunks, setAudioChunks] = useState([]);

	const [description, setDescription] = useState("");
	const [name, setName] = useState("");

	const [recentAudios, setRecentAudios] = useState([]);
	const [selectedAudio, setSelectedAudio] = useState(null);

	useEffect(() => {
		if (currentUser) {
			const fetchRecentAudios = async () => {
				const audiosQuery = query(
					collection(db, 'audio_by_user', currentUser.uid, 'audios'),
					orderBy('datetime', 'desc'),
					limit(10)
				);
				const querySnapshot = await getDocs(audiosQuery);
				setRecentAudios(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
			};
			fetchRecentAudios();
		}
	}, [currentUser]);

	const getMicrophonePermission = async () => {
		if ("MediaRecorder" in window) {
			try {
				const mediaStream = await navigator.mediaDevices.getUserMedia({
					audio: true,
					video: false,
				});
				setPermission(true);
				setStream(mediaStream);
			} catch (err) {
				alert(err.message);
			}
		} else {
			alert("The MediaRecorder API is not supported in your browser.");
		}
	};

	const startRecording = async () => {
		setRecordingStatus("recording");
		const media = new MediaRecorder(stream, { type: mimeType });

		mediaRecorder.current = media;

		mediaRecorder.current.start();

		let localAudioChunks = [];

		mediaRecorder.current.ondataavailable = (event) => {
			if (typeof event.data === "undefined") return;
			if (event.data.size === 0) return;
			localAudioChunks.push(event.data);
		};

		setAudioChunks(localAudioChunks);
	};

	const stopRecording = () => {
		setRecordingStatus("inactive");
		mediaRecorder.current.stop();

		mediaRecorder.current.onstop = async () => {
			const audioBlob = new Blob(audioChunks, { type: mimeType });
			const audioUrl = URL.createObjectURL(audioBlob);
			const storageRef = ref(storage, 'audio_by_user/' + currentUser.uid + '/' + name + '.mp3');
			try {
				await uploadBytes(storageRef, audioBlob);
				console.log('Uploaded a blob or file!');

				await addDoc(collection(db, 'audio_by_user', currentUser.uid, 'audios'), {
					datetime: serverTimestamp(),
					description: description,
					name: name,
					url: await getDownloadURL(storageRef),
					transcriptionStatus: "not started"
				});
			} catch (error) {
				console.error("Error uploading audio:", error);
				console.error("Error code:", error.code);
				console.error("Error message:", error.message);
			}
			setAudio(audioUrl);
			setAudioChunks([]);
		};
	};

	const handleAudioClick = (audioData) => {
		setSelectedAudio(audioData);
	};

	const handleMetadataUpdate = async () => {
		if (selectedAudio) {
			try {
				const audioDocRef = doc(db, 'audio_by_user', currentUser.uid, 'audios', selectedAudio.id);
				await updateDoc(audioDocRef, {
					description: selectedAudio.description,
					name: selectedAudio.name,
				});
				setRecentAudios(prevAudios => prevAudios.map(audio => audio.id === selectedAudio.id ? { ...audio, description: selectedAudio.description, name: selectedAudio.name } : audio));
				setSelectedAudio(null);
			} catch (error) {
				console.error("Error updating audio metadata:", error);
			}
		}
	};

	const handleTranscribe = async (audio) => {
		try {
			const audioDocRef = doc(db, 'audio_by_user', currentUser.uid, 'audios', audio.id);
			await updateDoc(audioDocRef, {
				transcriptionStatus: "pending"
			});
			setRecentAudios(prevAudios => prevAudios.map(a => a.id === audio.id ? { ...a, transcriptionStatus: "pending" } : a));
			await addDoc(collection(db, 'audios'), {
				uid: currentUser.uid,
				audioId: audio.id,
				name: audio.name,
				description: audio.description,
				url: audio.url
			});
		
		} catch (error) {
			console.error("Error updating transcription status:", error);
		}
	};

	const handleEditClick = (audio) => {
		setSelectedAudio(audio);
	};

	return (
		<div>
			<h2>Audio Recorder</h2>
			<main>
				<div className="audio-controls">
					{!permission ? (
						<button onClick={getMicrophonePermission} type="button">
							Get Microphone
						</button>
					) : null}
					{permission && recordingStatus === "inactive" ? (
						<div>
							<input
								type="text"
								placeholder="Enter audio name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<input
								type="text"
								placeholder="Enter description"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
							<button onClick={startRecording} type="button">
								Start Recording
							</button>
						</div>
					) : null}
					{recordingStatus === "recording" ? (
						<button onClick={stopRecording} type="button">
							Stop Recording
						</button>
					) : null}
				</div>
				{audio ? (
					<div className="audio-player">
						<audio src={audio} controls></audio>
						<a download href={audio}>
							Download Recording
						</a>
					</div>
				) : null}
				<div className="recent-audios">
					<h3>Recent Audios</h3>
					<table>
						<thead>
							<tr>
								<th>Name</th>
								<th>Description</th>
								<th>Date/Time</th>
								<th>Transcription Status</th>
								<th>Play</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{recentAudios.map((audio) => (
								<tr key={audio.id}>
									<td>{audio.name}</td>
									<td>{audio.description}</td>
									<td>{audio.datetime && new Date(audio.datetime.seconds * 1000).toLocaleString()}</td>
									<td>{audio.transcriptionStatus}</td>
									<td><audio src={audio.url} controls></audio></td>
									<td>
										{audio.transcript_url ? (
											<a href={audio.transcript_url} target="_blank" rel="noopener noreferrer">
												View Transcript
											</a>
										) : "N/A"}
									</td>
									<td>
										<button 
											onClick={() => handleTranscribe(audio)} 
											disabled={audio.transcriptionStatus === "pending"}
										>
											Transcribe
										</button>
										<button onClick={() => handleEditClick(audio)}>Edit</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				{selectedAudio && (
					<div className="audio-metadata">
						<h3>Edit Audio Metadata</h3>
						<input
							type="text"
							value={selectedAudio.name}
							onChange={(e) => setSelectedAudio({ ...selectedAudio, name: e.target.value })}
							placeholder="Edit audio name"
						/>
						<input
							type="text"
							value={selectedAudio.description}
							onChange={(e) => setSelectedAudio({ ...selectedAudio, description: e.target.value })}
							placeholder="Edit description"
						/>
						<button onClick={handleMetadataUpdate} type="button">
							Save Changes
						</button>
					</div>
				)}
			</main>
		</div>
	);
};

export default AudioRecorder;
