import React from 'react'
import { useAuth } from '../../contexts/authContext'
import { db , storage} from '../../firebase/firebase'
import { addDoc, collection, getDocs, getDoc, doc, updateDoc, orderBy, limit, query, where, serverTimestamp, onSnapshot } from "firebase/firestore";
import { ref, uploadString, uploadBytes, getDownloadURL } from "firebase/storage";
import { Container, Button, Row, Col, Card, CardTitle, CardText, Input, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip} from 'reactstrap';
import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'


const Screen = () =>{
  const statusMap = {
    1: "Sending content to server",
    2: "Server received content",
    3: "Server processing",
    4: "Permission denied",
    5: "Server finalizing solution",
    6: "Solution received",
    7: "Entry was previously processed",
    8: "Entry was previously removed"
  }
  const getStatus = (status) => {
    if (status in statusMap ) {
      return statusMap[status]
    }
    return "Unknown status"
  }
    const { currentUser,userLoggedIn } = useAuth()
    const [selectedLanguage, setSelectedLanguage] = useState('python'); 
    const [entries, setEntries] = useState();
    const [docId, setDocId] = useState();
    // const [text, setText] = useState("");
    const [image, setImage] = useState("null");
    const [uploadedImage, setUploadedImage] = useState(null);

    const entriesCollectionRef = collection(db, "screenshots_by_user", currentUser.uid, "entries");
    const recentActiveRef = query(entriesCollectionRef, where("active", "==", true), orderBy("time",  "desc"), limit(1))

    // update entries on snapshot, ordered by time, limit 1
    
    // const qry = query(entriesCollectionRef, orderBy("time", "desc"), limit(1));
    const [lastEntry, setLastEntry] = useState(null);
    const [active, setActive] = useState(false)
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    const handleCopyClick = async () => {
      try {
          await navigator.clipboard.writeText(entries[0].response);
      } catch (err) {
          console.error(
              "Unable to copy to clipboard.",
              err
          );
          alert("Copy to clipboard failed.");
      }
  };

  const handleGenerate = async () => {
    try {
        await addDoc(collection(db, "screenshots"), {
          screen:  uploadedImage ? uploadedImage : image,
          language: selectedLanguage,
          time: serverTimestamp(),
          uid: currentUser.uid,
          email: currentUser.email,
          docid: docId,
        });
        } catch (error) {
          console.error(
            "Unable to generate entry.", error
        );
    }
};
const handleClearImage = () => {
  setUploadedImage(null);
};
  const handleDelete = async () => {
    try {
       const docRef = doc(db, "screenshots_by_user", currentUser.uid, "entries", entries[0].id);
        // console.log(entries[0].id)
        await updateDoc(docRef, {
          active: false
        });
        } catch (error) {
          console.error(
            "Unable to delete entry.", error
        );
    }
};

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `screenshots_by_user/${currentUser.uid}/uploaded_images/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      setUploadedImage(downloadURL);
    }
  };

    useEffect(() => {
      
      const getEntries = async () => {
        const data = await getDocs(recentActiveRef)
        // setEntries(data.docs.map((elem) => ({ ...elem.data(), id: elem.id })))
        if (data && data.docs && data.docs.length > 0) {
                  //  setText(data.docs[0].data().text)
                  setEntries(data.docs.map((elem) => ({ ...elem.data(), id: elem.id })))
            }
      }
          



      const getHeartbeat = async () => {
        if (currentUser && currentUser.uid){
        const data = await getDoc(doc(db, "heartbeats", currentUser.uid));
        // console.log(data)
        if (data && data.data() ){
          if (data.data().login_time){
            // console.log(data.data().login_time);
            setActive(true)
          } 
          else if (data.data().logout_time){
            setActive(false)
            // console.log(data.data().login_time);
          }
        }
      }
      }
      const subscribeToHeartbeat = onSnapshot(doc(db, "heartbeats", currentUser.uid), (doc) => {
        // console.log(" in onsnapshot ")
        // console.log(snapshot.docs.data())
        // console.log(doc.data())
        if (doc && doc.data()){
          if (doc.data().login_time){
            // console.log(data.data().login_time);
            setActive(true)
          } 
          else{
            setActive(false)
            // console.log(data.data().login_time);
          }
        }
        });
      
  
      const subscribe = onSnapshot(entriesCollectionRef, (snapshot) => {
        // console.log(" in onsnapshot ")
        // console.log(snapshot.docs.data())
        snapshot.docs.map((firebase_doc) => {
        let data =firebase_doc.data();
        // console.log(data)
        if(data.hasOwnProperty('imageUrl')){
          setImage(data.imageUrl)
        }
        else {
        let img = new Image();
        img.src = data.screenshot;
        img.onload = function() {
        var canvas = document.createElement('canvas');  

        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        // const pixels = imageData.data; //[r,g,b,a,...]
        // for (var i = 0; i < pixels.length; i += 4) {
        //   const red = pixels[i];
        //   const green = pixels[i + 1];
        //   const blue = pixels[i + 2];
        //   const grayscale = RGBToGrayScale(red, green, blue)
        //   pixels[i] = grayscale;
        //   pixels[i + 1] = grayscale;
        //   pixels[i + 2] = grayscale;
        // }
        ctx.drawImage(img, 0, 0, img.width, img.height);

      
        var finalDataUrl = canvas.toDataURL("image/png");
        const storageRef = ref(storage, 'screenshots_by_user/'+currentUser.uid +'/images/'+firebase_doc.id+'.png');
        console.log('writing to storage', firebase_doc.id, currentUser.uid)
        uploadString(storageRef, finalDataUrl, 'data_url').then((snapshot) => {
          console.log('Uploaded a base64url string!');
        }).catch(function(error) {
          console.error("Error uploading screenshot:", error);
          console.error("Error code:", error.code);
          console.error("Error message:", error.message);
        });
        getDownloadURL(storageRef).then((url) => {
    setImage(url);
    const docRef = doc(db, "screenshots_by_user", currentUser.uid, "entries", firebase_doc.id);
     console.log("update image url", firebase_doc.id, currentUser.uid, url)
     updateDoc(docRef, {
      imageUrl: url,
      screenshot: ''
    });
  })
  .catch((error) => {
    console.error("Error getting document url:", error);
    setImage(finalDataUrl)
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
      case 'storage/object-not-found':
        // File doesn't exist
        break;
      case 'storage/unauthorized':
        // User doesn't have permission to access the object
        break;
      case 'storage/canceled':
        // User canceled the upload
        break;

      // ...

      case 'storage/unknown':
        // Unknown error occurred, inspect the server response
        break;
    }
  });

        
        }
      }
        // setText(data.text)
        setEntries([{ ...data, id: data.id }])
        setDocId(firebase_doc.id)
        });
      });

      
    //  getEntries()
    //  getHeartbeat()
    
     return ()=> subscribe() && subscribeToHeartbeat()
    }, [])

    return (
      userLoggedIn
      ?
     
 <>
<Modal isOpen={modal} toggle={toggle}   style={{maxWidth: '2000px', width: '100%'}} >
        <ModalBody>
        <img src = {image} alt = "screenshot"  name="image_name" />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
       <div className='text-2xl font-bold pt-14'>
        
        <Container>
        <a href="https://chrome.google.com/webstore/detail/ebdcbiijhjkdbgmhdgldjklbepkblndf">Get extension from chrome store</a>
          <Row>
            <Col sm="6">
            <p>Your Unique User ID is {currentUser.uid}</p>
        <p>Please copy and paste the User ID to your extension to capture content.</p>
            </Col>
            <Col sm="6">
            <p>Chrome extension status: {active ? <Button color="success">Connected </Button> :<Button color="danger">Disconnected </Button> }</p>
        <div>
        Language:
        <select
      value={selectedLanguage} // ...force the select's value to match the state variable...
      onChange={e => setSelectedLanguage(e.target.value)} // ... and update the state variable on any change!
    >
      <option value="python">python</option>
      <option value="java">Java</option>
      <option value="C++">C++</option>
      <option value="Javascript">Javascript</option>
    </select>
        </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Input type="file" onChange={handleImageUpload} />
              {uploadedImage && (
                <div className="mt-3">
                  {/* <img src={uploadedImage} alt="Uploaded Screenshot" style={{ maxWidth: '100%', height: 'auto' }} /> */}
                  <Button color="warning" onClick={handleClearImage} className="mt-2">Clear Uploaded Image</Button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
        {/* <div className='grid grid-cols-2'> */}
       
               
                  <div>
                    <Container>
                    <Row>
                      <Col sm="6">
                        <Card body>
                          <CardTitle tag="h5">
                            Captured Content (Use ALT + SHIFT + S to capture screenshot)
                          </CardTitle>
                          <CardText>
                          <img src = {uploadedImage ? uploadedImage : image} alt = "screenshot"   name="image_name" onClick={toggle} id="UncontrolledTooltip1"/>
                          <UncontrolledTooltip
      placement="top"
      target="UncontrolledTooltip1"
    >
      Click to zoom in
    </UncontrolledTooltip>
                          {/* <Input
    bsSize="lg"
    type="textarea"
    height = "400px"
    placeholder="Run Chrome extension to capture content"
    value={text}
    onChange={(e) => setText(e.target.value)}
  >
  </Input>        */}
                  
                          </CardText>
                         
                          <Button  color="info" onClick={handleGenerate}>
                            Generate Solution
                          </Button>
                        </Card>
                        {entries && entries.map(entry => {
                         return (
                      <Card body>
                          <CardTitle tag="h5">
                            Solution Code:
                          </CardTitle>
                          <CardText className="fw-normal">
                          <Input
    bsSize="lg"
    type="textarea"
    rows="30"
    value={entry && entry.response}
  >
  </Input>    
  <Button color="info" onClick={handleCopyClick}>
                            Copy
                          </Button>      
                          </CardText>
                        </Card>
                         );
                        })}
                      </Col>
                      
                      <Col sm="6">
                      {entries && entries.map(entry => {
                         return (
                        <Card body>
                          <CardTitle tag="h5">
                            Solution:
                            
                          </CardTitle>
                          <CardText className="fw-normal">
                          Status: {entry.status && getStatus(entry.status)}
                          <br/>
                          {entry && entry.initial && entry.initial.split('\n').map(str => <ReactMarkdown>{str}</ReactMarkdown>)}     
                        
  <br/>
  {entry && entry.summary && entry.summary.split('\n').map(str => <ReactMarkdown>{str}</ReactMarkdown>)}             
                          </CardText>
                        
                          <Button color="danger" onClick={handleDelete}>Delete</Button>
                        </Card>
                          );
                        })}
                      </Col>
                     
                    </Row>
                    <a href="https://firebasestorage.googleapis.com/v0/b/handwritten-852e8.appspot.com/o/AlgoAdvanceChromeExtensionAllURL.zip?alt=media&token=75de9324-9afa-4316-82b3-1dc89045d025">Download extension with no URL restriction</a>
                    </Container>
                 </div>
               
        {/* </div> */}


        
        </div>
        </>
        :

 <>
  {!userLoggedIn && (<Navigate to={'/login'} replace={true} />)}

 <div className='text-2xl font-bold pt-14'>
   Please login to access the workspace.
 </div>
</>
        
    )
}

export default Screen
