import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { useState, useRef, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';

const Problems = () => {
  const [searchParams] = useSearchParams();
  const [number, setNumber] = useState(searchParams.get("q"));

  // const number = searchParams.get("q"); // "text"
  console.log(number)

  const problems = ["673. Number of Longest Increasing Subsequence",
    "3170. Lexicographically Minimum String After Removing Stars",
    "935. Knight Dialer",
    "3206. Alternating Groups I",
    "1129. Shortest Path with Alternating Colors",
    "1408. String Matching in an Array",
    "703. Kth Largest Element in a Stream",
    "1304. Find N Unique Integers Sum up to Zero",
    "297. Serialize and Deserialize Binary Tree",
    "1312. Minimum Insertion Steps to Make a String Palindrome",
    "2874. Maximum Value of an Ordered Triplet II",
    "143. Reorder List",
    "1342. Number of Steps to Reduce a Number to Zero",
    "1743. Restore the Array From Adjacent Pairs",
    "1420. Build Array Where You Can Find The Maximum Exactly K Comparisons",
    "1534. Count Good Triplets",
    "2976. Minimum Cost to Convert String I",
    "3128. Right Triangles",
    "2709. Greatest Common Divisor Traversal",
    "2275. Largest Combination With Bitwise AND Greater Than Zero",
    "2729. Check if The Number is Fascinating",
    "430. Flatten a Multilevel Doubly Linked List",
    "371. Sum of Two Integers",
    "2762. Continuous Subarrays",
    "1239. Maximum Length of a Concatenated String with Unique Characters",
    "2426. Number of Pairs Satisfying Inequality",
    "138. Copy List with Random Pointer",
    "3224. Minimum Array Changes to Make Differences Equal",
    "1419. Minimum Number of Frogs Croaking",
    "1593. Split a String Into the Max Number of Unique Substrings",
    "2411. Smallest Subarrays With Maximum Bitwise OR",
    "2097. Valid Arrangement of Pairs",
    "1000. Minimum Cost to Merge Stones",
    "2147. Number of Ways to Divide a Long Corridor",
    "1796. Second Largest Digit in a String",
    "13. Roman to Integer",
    "1848. Minimum Distance to the Target Element",
    "743. Network Delay Time",
    "28. Find the Index of the First Occurrence in a String",
    "1122. Relative Sort Array",
    "380. Insert Delete GetRandom O(1)",
    "2274. Maximum Consecutive Floors Without Special Floors",
    "1961. Check If String Is a Prefix of Array",
    "542. 01 Matrix",
    "11. Container With Most Water",
    "1589. Maximum Sum Obtained of Any Permutation",
    "2148. Count Elements With Strictly Smaller and Greater Elements ",
    "1584. Min Cost to Connect All Points",
    "926. Flip String to Monotone Increasing",
    "142. Linked List Cycle II",
    "112. Path Sum",
    "2270. Number of Ways to Split Array",
    "3029. Minimum Time to Revert Word to Initial State I",
    "3130. Find All Possible Stable Binary Arrays II",
    "3138. Minimum Length of Anagram Concatenation",
    "354. Russian Doll Envelopes",
    "812. Largest Triangle Area",
    "1307. Verbal Arithmetic Puzzle",
    "2658. Maximum Number of Fish in a Grid",
    "3017. Count the Number of Houses at a Certain Distance II",
    "1436. Destination City",
    "2997. Minimum Number of Operations to Make Array XOR Equal to K",
    "1915. Number of Wonderful Substrings",
    "2540. Minimum Common Value",
    "891. Sum of Subsequence Widths",
    "2336. Smallest Number in Infinite Set",
    "2319. Check if Matrix Is X-Matrix",
    "448. Find All Numbers Disappeared in an Array",
    "2835. Minimum Operations to Form Subsequence With Target Sum",
    "514. Freedom Trail",
    "2850. Minimum Moves to Spread Stones Over Grid",
    "1993. Operations on Tree",
    "2211. Count Collisions on a Road",
    "2424. Longest Uploaded Prefix",
    "4. Median of Two Sorted Arrays",
    "999. Available Captures for Rook",
    "14. Longest Common Prefix",
    "809. Expressive Words",
    "623. Add One Row to Tree",
    "2442. Count Number of Distinct Integers After Reverse Operations",
    "823. Binary Trees With Factors",
    "2846. Minimum Edge Weight Equilibrium Queries in a Tree",
    "654. Maximum Binary Tree",
    "200. Number of Islands",
    "1477. Find Two Non-overlapping Sub-arrays Each With Target Sum",
    "3195. Find the Minimum Area to Cover All Ones I",
    "1817. Finding the Users Active Minutes",
    "81. Search in Rotated Sorted Array II",
    "2864. Maximum Odd Binary Number",
    "632. Smallest Range Covering Elements from K Lists",
    "1716. Calculate Money in Leetcode Bank",
    "2918. Minimum Equal Sum of Two Arrays After Replacing Zeros",
    "744. Find Smallest Letter Greater Than Target",
    "652. Find Duplicate Subtrees",
    "2181. Merge Nodes in Between Zeros",
    "1267. Count Servers that Communicate",
    "2772. Apply Operations to Make All Array Elements Equal to Zero",
    "2788. Split Strings by Separator",
    "931. Minimum Falling Path Sum",
    "52. N-Queens II",
    "2602. Minimum Operations to Make All Array Elements Equal",
    "1278. Palindrome Partitioning III",
    "1268. Search Suggestions System",
    "2202. Maximize the Topmost Element After K Moves",
    "2262. Total Appeal of A String",
    "2239. Find Closest Number to Zero",
    "3075. Maximize Happiness of Selected Children",
    "1665. Minimum Initial Energy to Finish Tasks",
    "1814. Count Nice Pairs in an Array",
    "2364. Count Number of Bad Pairs",
    "423. Reconstruct Original Digits from English",
    "443. String Compression",
    "1921. Eliminate Maximum Number of Monsters",
    "3003. Maximize the Number of Partitions After Operations",
    "2467. Most Profitable Path in a Tree",
    "981. Time Based Key-Value Store",
    "1184. Distance Between Bus Stops",
    "2901. Longest Unequal Adjacent Groups Subsequence II",
    "1982. Find Array Given Subset Sums",
    "2842. Count K-Subsequences of a String With Maximum Beauty",
    "2826. Sorting Three Groups",
    "209. Minimum Size Subarray Sum",
    "1042. Flower Planting With No Adjacent",
    "2125. Number of Laser Beams in a Bank",
    "3143. Maximum Points Inside the Square",
    "236. Lowest Common Ancestor of a Binary Tree",
    "556. Next Greater Element III",
    "2092. Find All People With Secret",
    "871. Minimum Number of Refueling Stops",
    "2817. Minimum Absolute Difference Between Elements With Constraint",
    "778. Swim in Rising Water",
    "1523. Count Odd Numbers in an Interval Range",
    "2563. Count the Number of Fair Pairs",
    "373. Find K Pairs with Smallest Sums",
    "3031. Minimum Time to Revert Word to Initial State II",
    "1362. Closest Divisors",
    "832. Flipping an Image",
    "2310. Sum of Numbers With Units Digit K",
    "1985. Find the Kth Largest Integer in the Array",
    "2311. Longest Binary Subsequence Less Than or Equal to K",
    "1343. Number of Sub-arrays of Size K and Average Greater than or Equal to Threshold",
    "1014. Best Sightseeing Pair",
    "475. Heaters",
    "1664. Ways to Make a Fair Array",
    "3127. Make a Square with the Same Color",
    "238. Product of Array Except Self",
    "1093. Statistics from a Large Sample",
    "2234. Maximum Total Beauty of the Gardens",
    "831. Masking Personal Information",
    "2105. Watering Plants II",
    "913. Cat and Mouse",
    "2869. Minimum Operations to Collect Elements",
    "2397. Maximum Rows Covered by Columns",
    "1720. Decode XORed Array",
    "70. Climbing Stairs",
    "904. Fruit Into Baskets",
    "381. Insert Delete GetRandom O(1) - Duplicates allowed",
    "1771. Maximize Palindrome Length From Subsequences",
    "1639. Number of Ways to Form a Target String Given a Dictionary",
    "2317. Maximum XOR After Operations ",
    "1039. Minimum Score Triangulation of Polygon",
    "1566. Detect Pattern of Length M Repeated K or More Times",
    "1207. Unique Number of Occurrences",
    "1691. Maximum Height by Stacking Cuboids ",
    "1434. Number of Ways to Wear Different Hats to Each Other",
    "171. Excel Sheet Column Number",
    "1400. Construct K Palindrome Strings",
    "242. Valid Anagram",
    "1061. Lexicographically Smallest Equivalent String",
    "2446. Determine if Two Events Have Conflict",
    "122. Best Time to Buy and Sell Stock II",
    "24. Swap Nodes in Pairs",
    "1658. Minimum Operations to Reduce X to Zero",
    "3158. Find the XOR of Numbers Which Appear Twice",
    "1969. Minimum Non-Zero Product of the Array Elements",
    "3181. Maximum Total Reward Using Operations II",
    "888. Fair Candy Swap",
    "2182. Construct String With Repeat Limit",
    "1154. Day of the Year",
    "622. Design Circular Queue",
    "2358. Maximum Number of Groups Entering a Competition",
    "3112. Minimum Time to Visit Disappearing Nodes",
    "3098. Find the Sum of Subsequence Powers",
    "856. Score of Parentheses",
    "331. Verify Preorder Serialization of a Binary Tree",
    "1493. Longest Subarray of 1's After Deleting One Element",
    "2341. Maximum Number of Pairs in Array",
    "829. Consecutive Numbers Sum",
    "2894. Divisible and Non-divisible Sums Difference",
    "1702. Maximum Binary String After Change",
    "2320. Count Number of Ways to Place Houses",
    "204. Count Primes",
    "3232. Find if Digit Game Can Be Won",
    "2829. Determine the Minimum Sum of a k-avoiding Array",
    "2386. Find the K-Sum of an Array",
    "1536. Minimum Swaps to Arrange a Binary Grid",
    "2568. Minimum Impossible OR",
    "394. Decode String",
    "2435. Paths in Matrix Whose Sum Is Divisible by K",
    "938. Range Sum of BST",
    "896. Monotonic Array",
    "1824. Minimum Sideway Jumps",
    "2751. Robot Collisions",
    "732. My Calendar III",
    "1888. Minimum Number of Flips to Make the Binary String Alternating",
    "3115. Maximum Prime Difference",
    "910. Smallest Range II",
    "2420. Find All Good Indices",
    "1049. Last Stone Weight II",
    "173. Binary Search Tree Iterator",
    "3142. Check if Grid Satisfies Conditions",
    "222. Count Complete Tree Nodes",
    "1324. Print Words Vertically",
    "3228. Maximum Number of Operations to Move Ones to the End",
    "1208. Get Equal Substrings Within Budget",
    "148. Sort List",
    "975. Odd Even Jump",
    "2514. Count Anagrams",
    "3171. Find Subarray With Bitwise OR Closest to K",
    "2800. Shortest String That Contains Three Strings",
    "859. Buddy Strings",
    "2958. Length of Longest Subarray With at Most K Frequency",
    "1835. Find XOR Sum of All Pairs Bitwise AND",
    "405. Convert a Number to Hexadecimal",
    "385. Mini Parser",
    "1975. Maximum Matrix Sum",
    "1896. Minimum Cost to Change the Final Value of Expression",
    "1457. Pseudo-Palindromic Paths in a Binary Tree",
    "1963. Minimum Number of Swaps to Make the String Balanced",
    "752. Open the Lock",
    "2469. Convert the Temperature",
    "2520. Count the Digits That Divide a Number",
    "2220. Minimum Bit Flips to Convert Number",
    "2024. Maximize the Confusion of an Exam",
    "2427. Number of Common Factors",
    "1686. Stone Game VI",
    "283. Move Zeroes",
    "3223. Minimum Length of String After Operations",
    "2392. Build a Matrix With Conditions",
    "901. Online Stock Span",
    "289. Game of Life",
    "2492. Minimum Score of a Path Between Two Cities",
    "1914. Cyclically Rotating a Grid",
    "2071. Maximum Number of Tasks You Can Assign",
    "2006. Count Number of Pairs With Absolute Difference K",
    "756. Pyramid Transition Matrix",
    "207. Course Schedule",
    "2337. Move Pieces to Obtain a String",
    "3132. Find the Integer Added to Array II",
    "540. Single Element in a Sorted Array",
    "1455. Check If a Word Occurs As a Prefix of Any Word in a Sentence",
    "233. Number of Digit One",
    "520. Detect Capital",
    "2132. Stamping the Grid",
    "1464. Maximum Product of Two Elements in an Array",
    "515. Find Largest Value in Each Tree Row",
    "1531. String Compression II",
    "2872. Maximum Number of K-Divisible Components",
    "2108. Find First Palindromic String in the Array",
    "1287. Element Appearing More Than 25% In Sorted Array",
    "2418. Sort the People",
    "2085. Count Common Words With One Occurrence",
    "949. Largest Time for Given Digits",
    "1338. Reduce Array Size to The Half",
    "678. Valid Parenthesis String",
    "1297. Maximum Number of Occurrences of a Substring",
    "190. Reverse Bits",
    "2439. Minimize Maximum of Array",
    "713. Subarray Product Less Than K",
    "1156. Swap For Longest Repeated Character Substring",
    "224. Basic Calculator",
    "1576. Replace All ?'s to Avoid Consecutive Repeating Characters",
    "1837. Sum of Digits in Base K",
    "1603. Design Parking System",
    "2363. Merge Similar Items",
    "1561. Maximum Number of Coins You Can Get",
    "2437. Number of Valid Clock Times",
    "827. Making A Large Island",
    "2367. Number of Arithmetic Triplets",
    "2453. Destroy Sequential Targets",
    "3046. Split the Array",
    "605. Can Place Flowers",
    "700. Search in a Binary Search Tree",
    "1143. Longest Common Subsequence",
    "2908. Minimum Sum of Mountain Triplets I",
    "1718. Construct the Lexicographically Largest Valid Sequence",
    "1711. Count Good Meals",
    "806. Number of Lines To Write String",
    "1638. Count Substrings That Differ by One Character",
    "1351. Count Negative Numbers in a Sorted Matrix",
    "2740. Find the Value of the Partition",
    "893. Groups of Special-Equivalent Strings",
    "65. Valid Number",
    "1694. Reformat Phone Number",
    "2732. Find a Good Subset of the Matrix",
    "1605. Find Valid Matrix Given Row and Column Sums",
    "393. UTF-8 Validation",
    "2522. Partition String Into Substrings With Values at Most K",
    "640. Solve the Equation",
    "572. Subtree of Another Tree",
    "160. Intersection of Two Linked Lists",
    "1672. Richest Customer Wealth",
    "2109. Adding Spaces to a String",
    "1170. Compare Strings by Frequency of the Smallest Character",
    "2949. Count Beautiful Substrings II",
    "1053. Previous Permutation With One Swap",
    "554. Brick Wall",
    "3120. Count the Number of Special Characters I",
    "2511. Maximum Enemy Forts That Can Be Captured",
    "1942. The Number of the Smallest Unoccupied Chair",
    "1901. Find a Peak Element II",
    "2696. Minimum String Length After Removing Substrings",
    "1161. Maximum Level Sum of a Binary Tree",
    "1508. Range Sum of Sorted Subarray Sums",
    "1249. Minimum Remove to Make Valid Parentheses",
    "2716. Minimize String Length",
    "1358. Number of Substrings Containing All Three Characters",
    "1895. Largest Magic Square",
    "2786. Visit Array Positions to Maximize Score",
    "939. Minimum Area Rectangle",
    "335. Self Crossing",
    "2178. Maximum Split of Positive Even Integers",
    "395. Longest Substring with At Least K Repeating Characters",
    "1432. Max Difference You Can Get From Changing an Integer",
    "2569. Handling Sum Queries After Update",
    "1882. Process Tasks Using Servers",
    "2968. Apply Operations to Maximize Frequency Score",
    "745. Prefix and Suffix Search",
    "3164. Find the Number of Good Pairs II",
    "22. Generate Parentheses",
    "3065. Minimum Operations to Exceed Threshold Value I",
    "989. Add to Array-Form of Integer",
    "3002. Maximum Size of a Set After Removals",
    "1717. Maximum Score From Removing Substrings",
    "628. Maximum Product of Three Numbers",
    "2400. Number of Ways to Reach a Position After Exactly k Steps",
    "449. Serialize and Deserialize BST",
    "1047. Remove All Adjacent Duplicates In String",
    "2699. Modify Graph Edge Weights",
    "299. Bulls and Cows",
    "1575. Count All Possible Routes",
    "1038. Binary Search Tree to Greater Sum Tree",
    "991. Broken Calculator",
    "2543. Check if Point Is Reachable",
    "2172. Maximum AND Sum of Array",
    "2876. Count Visited Nodes in a Directed Graph",
    "3212. Count Submatrices With Equal Frequency of X and Y",
    "2316. Count Unreachable Pairs of Nodes in an Undirected Graph",
    "507. Perfect Number",
    "1962. Remove Stones to Minimize the Total",
    "2149. Rearrange Array Elements by Sign",
    "2566. Maximum Difference by Remapping a Digit",
    "2944. Minimum Number of Coins for Fruits",
    "2909. Minimum Sum of Mountain Triplets II",
    "1654. Minimum Jumps to Reach Home",
    "1515. Best Position for a Service Centre",
    "2463. Minimum Total Distance Traveled",
    "2421. Number of Good Paths",
    "66. Plus One",
    "2455. Average Value of Even Numbers That Are Divisible by Three",
    "691. Stickers to Spell Word",
    "1476. Subrectangle Queries",
    "92. Reverse Linked List II",
    "1657. Determine if Two Strings Are Close",
    "1218. Longest Arithmetic Subsequence of Given Difference",
    "1296. Divide Array in Sets of K Consecutive Numbers",
    "1876. Substrings of Size Three with Distinct Characters",
    "1379. Find a Corresponding Node of a Binary Tree in a Clone of That Tree",
    "649. Dota2 Senate",
    "2294. Partition Array Such That Maximum Difference Is K",
    "2749. Minimum Operations to Make the Integer Zero",
    "648. Replace Words",
    "1054. Distant Barcodes",
    "1041. Robot Bounded In Circle",
    "2192. All Ancestors of a Node in a Directed Acyclic Graph",
    "2451. Odd String Difference",
    "432. All O`one Data Structure",
    "576. Out of Boundary Paths",
    "563. Binary Tree Tilt",
    "1727. Largest Submatrix With Rearrangements",
    "205. Isomorphic Strings",
    "2458. Height of Binary Tree After Subtree Removal Queries",
    "964. Least Operators to Express Number",
    "1630. Arithmetic Subarrays",
    "3200. Maximum Height of a Triangle",
    "2956. Find Common Elements Between Two Arrays",
    "2242. Maximum Score of a Node Sequence",
    "43. Multiply Strings",
    "438. Find All Anagrams in a String",
    "783. Minimum Distance Between BST Nodes",
    "3035. Maximum Palindromes After Operations",
    "1009. Complement of Base 10 Integer",
    "837. New 21 Game",
    "2095. Delete the Middle Node of a Linked List",
    "873. Length of Longest Fibonacci Subsequence",
    "1544. Make The String Great",
    "3240. Minimum Number of Flips to Make Binary Grid Palindromic II",
    "2465. Number of Distinct Averages",
    "2256. Minimum Average Difference",
    "1790. Check if One String Swap Can Make Strings Equal",
    "2384. Largest Palindromic Number",
    "1394. Find Lucky Integer in an Array",
    "1320. Minimum Distance to Type a Word Using Two Fingers",
    "2222. Number of Ways to Select Buildings",
    "61. Rotate List",
    "878. Nth Magical Number",
    "1681. Minimum Incompatibility",
    "1289. Minimum Falling Path Sum II",
    "551. Student Attendance Record I",
    "2930. Number of Strings Which Can Be Rearranged to Contain Substring",
    "427. Construct Quad Tree",
    "3091. Apply Operations to Make Sum of Array Greater Than or Equal to k",
    "93. Restore IP Addresses",
    "1163. Last Substring in Lexicographical Order",
    "412. Fizz Buzz",
    "1461. Check If a String Contains All Binary Codes of Size K",
    "1402. Reducing Dishes",
    "2660. Determine the Winner of a Bowling Game",
    "2831. Find the Longest Equal Subarray",
    "669. Trim a Binary Search Tree",
    "2457. Minimum Addition to Make Integer Beautiful",
    "1563. Stone Game V",
    "1444. Number of Ways of Cutting a Pizza",
    "1108. Defanging an IP Address",
    "1609. Even Odd Tree",
    "2866. Beautiful Towers II",
    "34. Find First and Last Position of Element in Sorted Array",
    "2249. Count Lattice Points Inside a Circle",
    "2221. Find Triangular Sum of an Array",
    "1521. Find a Value of a Mysterious Function Closest to Target",
    "2518. Number of Great Partitions",
    "2558. Take Gifts From the Richest Pile",
    "2947. Count Beautiful Substrings I",
    "2013. Detect Squares",
    "91. Decode Ways",
    "1201. Ugly Number III",
    "3080. Mark Elements on Array by Performing Queries",
    "2000. Reverse Prefix of Word",
    "1035. Uncrossed Lines",
    "90. Subsets II",
    "2781. Length of the Longest Valid Substring",
    "3238. Find the Number of Winning Players",
    "2840. Check if Strings Can be Made Equal With Operations II",
    "2023. Number of Pairs of Strings With Concatenation Equal to Target",
    "781. Rabbits in Forest",
    "1029. Two City Scheduling",
    "2571. Minimum Operations to Reduce an Integer to 0",
    "2897. Apply Operations on Array to Maximize Sum of Squares",
    "1128. Number of Equivalent Domino Pairs",
    "144. Binary Tree Preorder Traversal",
    "1760. Minimum Limit of Balls in a Bag",
    "633. Sum of Square Numbers",
    "2016. Maximum Difference Between Increasing Elements",
    "2405. Optimal Partition of String",
    "2906. Construct Product Matrix",
    "2396. Strictly Palindromic Number",
    "1406. Stone Game III",
    "675. Cut Off Trees for Golf Event",
    "453. Minimum Moves to Equal Array Elements",
    "1340. Jump Game V",
    "2357. Make Array Zero by Subtracting Equal Amounts",
    "1818. Minimum Absolute Sum Difference",
    "1844. Replace All Digits with Characters",
    "2982. Find Longest Special Substring That Occurs Thrice II",
    "41. First Missing Positive",
    "2203. Minimum Weighted Subgraph With the Required Paths",
    "898. Bitwise ORs of Subarrays",
    "307. Range Sum Query - Mutable",
    "419. Battleships in a Board",
    "1679. Max Number of K-Sum Pairs",
    "1832. Check if the Sentence Is Pangram",
    "1452. People Whose List of Favorite Companies Is Not a Subset of Another List",
    "992. Subarrays with K Different Integers",
    "2179. Count Good Triplets in an Array",
    "1642. Furthest Building You Can Reach",
    "1913. Maximum Product Difference Between Two Pairs",
    "1706. Where Will the Ball Fall",
    "2719. Count of Integers",
    "3021. Alice and Bob Playing Flower Game",
    "473. Matchsticks to Square",
    "1535. Find the Winner of an Array Game",
    "557. Reverse Words in a String III",
    "2935. Maximum Strong Pair XOR II",
    "2099. Find Subsequence of Length K With the Largest Sum",
    "1967. Number of Strings That Appear as Substrings in Word",
    "1860. Incremental Memory Leak",
    "2616. Minimize the Maximum Difference of Pairs",
    "2062. Count Vowel Substrings of a String",
    "984. String Without AAA or BBB",
    "1528. Shuffle String",
    "3076. Shortest Uncommon Substring in an Array",
    "445. Add Two Numbers II",
    "2471. Minimum Number of Operations to Sort a Binary Tree by Level",
    "1339. Maximum Product of Splitted Binary Tree",
    "1487. Making File Names Unique",
    "2952. Minimum Number of Coins to be Added",
    "2744. Find Maximum Number of String Pairs",
    "1349. Maximum Students Taking Exam",
    "2785. Sort Vowels in a String",
    "2164. Sort Even and Odd Indices Independently",
    "1542. Find Longest Awesome Substring",
    "671. Second Minimum Node In a Binary Tree",
    "3039. Apply Operations to Make String Empty",
    "1175. Prime Arrangements",
    "77. Combinations",
    "2056. Number of Valid Move Combinations On Chessboard",
    "903. Valid Permutations for DI Sequence",
    "2697. Lexicographically Smallest Palindrome",
    "1462. Course Schedule IV",
    "2809. Minimum Time to Make Array Sum At Most x",
    "1937. Maximum Number of Points with Cost",
    "1721. Swapping Nodes in a Linked List",
    "211. Design Add and Search Words Data Structure",
    "1223. Dice Roll Simulation",
    "1488. Avoid Flood in The City",
    "2276. Count Integers in Intervals",
    "2871. Split Array Into Maximum Number of Subarrays",
    "2502. Design Memory Allocator",
    "909. Snakes and Ladders",
    "3243. Shortest Distance After Road Addition Queries I",
    "968. Binary Tree Cameras",
    "1313. Decompress Run-Length Encoded List",
    "437. Path Sum III",
    "3105. Longest Strictly Increasing or Strictly Decreasing Subarray",
    "1491. Average Salary Excluding the Minimum and Maximum Salary",
    "169. Majority Element",
    "638. Shopping Offers",
    "1774. Closest Dessert Cost",
    "3180. Maximum Total Reward Using Operations I",
    "2104. Sum of Subarray Ranges",
    "3095. Shortest Subarray With OR at Least K I",
    "990. Satisfiability of Equality Equations",
    "2586. Count the Number of Vowel Strings in Range",
    "1316. Distinct Echo Substrings",
    "3034. Number of Subarrays That Match a Pattern I",
    "2770. Maximum Number of Jumps to Reach the Last Index",
    "2962. Count Subarrays Where Max Element Appears at Least K Times",
    "1146. Snapshot Array",
    "79. Word Search",
    "925. Long Pressed Name",
    "1577. Number of Ways Where Square of Number Is Equal to Product of Two Numbers",
    "1862. Sum of Floored Pairs",
    "637. Average of Levels in Binary Tree",
    "1669. Merge In Between Linked Lists",
    "2679. Sum in a Matrix",
    "319. Bulb Switcher",
    "100. Same Tree",
    "720. Longest Word in Dictionary",
    "1569. Number of Ways to Reorder Array to Get Same BST",
    "2768. Number of Black Blocks",
    "2227. Encrypt and Decrypt Strings",
    "145. Binary Tree Postorder Traversal",
    "529. Minesweeper",
    "2065. Maximum Path Quality of a Graph",
    "2860. Happy Students",
    "3048. Earliest Second to Mark Indices I",
    "1931. Painting a Grid With Three Different Colors",
    "1008. Construct Binary Search Tree from Preorder Traversal",
    "2806. Account Balance After Rounded Purchase",
    "2904. Shortest and Lexicographically Smallest Beautiful String",
    "795. Number of Subarrays with Bounded Maximum",
    "2033. Minimum Operations to Make a Uni-Value Grid",
    "googlead2c46cdbfa0e008.html",
    "2843.   Count Symmetric Integers",
    "1505. Minimum Possible Integer After at Most K Adjacent Swaps On Digits",
    "1546. Maximum Number of Non-Overlapping Subarrays With Sum Equals Target",
    "889. Construct Binary Tree from Preorder and Postorder Traversal",
    "966. Vowel Spellchecker",
    "606. Construct String from Binary Tree",
    "1025. Divisor Game",
    "1222. Queens That Can Attack the King",
    "765. Couples Holding Hands",
    "1269. Number of Ways to Stay in the Same Place After Some Steps",
    "322. Coin Change",
    "234. Palindrome Linked List",
    "2825. Make String a Subsequence Using Cyclic Increments",
    "2117. Abbreviating the Product of a Range",
    "2957. Remove Adjacent Almost-Equal Characters",
    "1277. Count Square Submatrices with All Ones",
    "124. Binary Tree Maximum Path Sum",
    "2476. Closest Nodes Queries in a Binary Search Tree",
    "2981. Find Longest Special Substring That Occurs Thrice I",
    "828. Count Unique Characters of All Substrings of a Given String",
    "2999. Count the Number of Powerful Integers",
    "2374. Node With Highest Edge Score",
    "199. Binary Tree Right Side View",
    "1594. Maximum Non Negative Product in a Matrix",
    "773. Sliding Puzzle",
    "452. Minimum Number of Arrows to Burst Balloons",
    "1800. Maximum Ascending Subarray Sum",
    "301. Remove Invalid Parentheses",
    "3196. Maximize Total Cost of Alternating Subarrays",
    "1237. Find Positive Integer Solution for a Given Equation",
    "770. Basic Calculator IV",
    "954. Array of Doubled Pairs",
    "746. Min Cost Climbing Stairs",
    "916. Word Subsets",
    "2536. Increment Submatrices by One",
    "1275. Find Winner on a Tic Tac Toe Game",
    "3146. Permutation Difference between Two Strings",
    "2075. Decode the Slanted Ciphertext",
    "468. Validate IP Address",
    "303. Range Sum Query - Immutable",
    "2267.  Check if There Is a Valid Parentheses String Path",
    "350. Intersection of Two Arrays II",
    "1524. Number of Sub-arrays With Odd Sum",
    "1881. Maximum Value after Insertion",
    "731. My Calendar II",
    "1799. Maximize Score After N Operations",
    "9. Palindrome Number",
    "844. Backspace String Compare",
    "543. Diameter of Binary Tree",
    "349. Intersection of Two Arrays",
    "336. Palindrome Pairs",
    "2260. Minimum Consecutive Cards to Pick Up",
    "1414. Find the Minimum Number of Fibonacci Numbers Whose Sum Is K",
    "1510. Stone Game IV",
    "2546. Apply Bitwise Operations to Make Strings Equal",
    "826. Most Profit Assigning Work",
    "2712. Minimum Cost to Make All Characters Equal",
    "1103. Distribute Candies to People",
    "2967. Minimum Cost to Make Array Equalindromic",
    "2940. Find Building Where Alice and Bob Can Meet",
    "1643. Kth Smallest Instructions",
    "2644. Find the Maximum Divisibility Score",
    "1363. Largest Multiple of Three",
    "1900. The Earliest and Latest Rounds Where Players Compete",
    "3260. Find the Largest Palindrome Divisible by K",
    "49. Group Anagrams",
    "123. Best Time to Buy and Sell Stock III",
    "3202. Find the Maximum Length of Valid Subsequence II",
    "2303. Calculate Amount Paid in Taxes",
    "1765. Map of Highest Peak",
    "3086. Minimum Moves to Pick K Ones",
    "258. Add Digits",
    "2739. Total Distance Traveled",
    "2535. Difference Between Element Sum and Digit Sum of an Array",
    "3217. Delete Nodes From Linked List Present in Array",
    "2090. K Radius Subarray Averages",
    "1438. Longest Continuous Subarray With Absolute Diff Less Than or Equal to Limit",
    "2039. The Time When the Network Becomes Idle",
    "279. Perfect Squares",
    "3116. Kth Smallest Amount With Single Denomination Combination",
    "2185. Counting Words With a Given Prefix",
    "2127. Maximum Employees to Be Invited to a Meeting",
    "1954. Minimum Garden Perimeter to Collect Enough Apples",
    "1547. Minimum Cost to Cut a Stick",
    "592. Fraction Addition and Subtraction",
    "2416. Sum of Prefix Scores of Strings",
    "818. Race Car",
    "2073. Time Needed to Buy Tickets",
    "2380. Time Needed to Rearrange a Binary String",
    "1253. Reconstruct a 2-Row Binary Matrix",
    "1617. Count Subtrees With Max Distance Between Cities",
    "87. Scramble String",
    "501. Find Mode in Binary Search Tree",
    "3096. Minimum Levels to Gain More Points",
    "833. Find And Replace in String",
    "738. Monotone Increasing Digits",
    "378. Kth Smallest Element in a Sorted Matrix",
    "1916. Count Ways to Build Rooms in an Ant Colony",
    "2588. Count the Number of Beautiful Subarrays",
    "2970. Count the Number of Incremovable Subarrays I",
    "717. 1-bit and 2-bit Characters",
    "2017. Grid Game",
    "1399. Count Largest Group",
    "1319. Number of Operations to Make Network Connected",
    "35. Search Insert Position",
    "416. Partition Equal Subset Sum",
    "1964. Find the Longest Valid Obstacle Course at Each Position",
    "504. Base 7",
    "680. Valid Palindrome II",
    "1309. Decrypt String from Alphabet to Integer Mapping",
    "655. Print Binary Tree",
    "503. Next Greater Element II",
    "1910. Remove All Occurrences of a Substring",
    "1526. Minimum Number of Increments on Subarrays to Form a Target Array",
    "2188. Minimum Time to Finish the Race",
    "754. Reach a Number",
    "109. Convert Sorted List to Binary Search Tree",
    "730. Count Different Palindromic Subsequences",
    "2914. Minimum Number of Changes to Make Binary String Beautiful",
    "726. Number of Atoms",
    "874. Walking Robot Simulation",
    "1410. HTML Entity Parser",
    "3106. Lexicographically Smallest String After Operations With Constraint",
    "1893. Check if All the Integers in a Range Are Covered",
    "3152. Special Array II",
    "658. Find K Closest Elements",
    "3151. Special Array I",
    "2301. Match Substring After Replacement",
    "328. Odd Even Linked List",
    "2365. Task Scheduler II",
    "2836. Maximize Value of Function in a Ball Passing Game",
    "2945. Find Maximum Non-decreasing Array Length",
    "2899. Last Visited Integers",
    "982. Triples with Bitwise AND Equal To Zero",
    "2565. Subsequence With the Minimum Score",
    "2477. Minimum Fuel Cost to Report to the Capital",
    "1864. Minimum Number of Swaps to Make the Binary String Alternating",
    "3216. Lexicographically Smallest String After a Swap",
    "1624. Largest Substring Between Two Equal Characters",
    "3069. Distribute Elements Into Two Arrays I",
    "2943. Maximize Area of Square Hole in Grid",
    "848. Shifting Letters",
    "2126. Destroying Asteroids",
    "2280. Minimum Lines to Represent a Line Chart",
    "1232. Check If It Is a Straight Line",
    "496. Next Greater Element I",
    "914. X of a Kind in a Deck of Cards",
    "2290. Minimum Obstacle Removal to Reach Corner",
    "973. K Closest Points to Origin",
    "1347. Minimum Number of Steps to Make Two Strings Anagram",
    "40. Combination Sum II",
    "309. Best Time to Buy and Sell Stock with Cooldown",
    "2521. Distinct Prime Factors of Product of Array",
    "3022. Minimize OR of Remaining Elements Using Operations",
    "2162. Minimum Cost to Set Cooking Time",
    "1558. Minimum Numbers of Function Calls to Make Target Array",
    "1880. Check if Word Equals Summation of Two Words",
    "1425. Constrained Subsequence Sum",
    "2245. Maximum Trailing Zeros in a Cornered Path",
    "706. Design HashMap",
    "1851. Minimum Interval to Include Each Query",
    "2030. Smallest K-Length Subsequence With Occurrences of a Letter",
    "282. Expression Add Operators",
    "470. Implement Rand10() Using Rand7()",
    "735. Asteroid Collision",
    "1403. Minimum Subsequence in Non-Increasing Order",
    "2512. Reward Top K Students",
    "1631. Path With Minimum Effort",
    "1238. Circular Permutation in Binary Representation",
    "668. Kth Smallest Number in Multiplication Table",
    "912. Sort an Array",
    "1483. Kth Ancestor of a Tree Node",
    "2171. Removing Minimum Number of Magic Beans",
    "2284. Sender With Largest Word Count",
    "367. Valid Perfect Square",
    "3175. Find The First Player to win K Games in a Row",
    "1656. Design an Ordered Stream",
    "2589. Minimum Time to Complete All Tasks",
    "3026. Maximum Good Subarray Sum",
    "104. Maximum Depth of Binary Tree",
    "1391. Check if There is a Valid Path in a Grid",
    "2296. Design a Text Editor",
    "2567. Minimum Score by Changing Two Elements",
    "753. Cracking the Safe",
    "2213. Longest Substring of One Repeating Character",
    "2928. Distribute Candies Among Children I",
    "983. Minimum Cost For Tickets",
    "729. My Calendar I",
    "2461. Maximum Sum of Distinct Subarrays With Length K",
    "1453. Maximum Number of Darts Inside of a Circular Dartboard",
    "2830. Maximize the Profit as the Salesman",
    "2177. Find Three Consecutive Integers That Sum to a Given Number",
    "2780. Minimum Index of a Valid Split",
    "2409. Count Days Spent Together",
    "1601. Maximum Number of Achievable Transfer Requests",
    "407. Trapping Rain Water II",
    "2047. Number of Valid Words in a Sentence",
    "880. Decoded String at Index",
    "3213. Construct String with Minimum Cost",
    "1696. Jump Game VI",
    "1433. Check If a String Can Break Another String",
    "1130. Minimum Cost Tree From Leaf Values",
    "1879. Minimum XOR Sum of Two Arrays",
    "115. Distinct Subsequences",
    "659. Split Array into Consecutive Subsequences",
    "2798. Number of Employees Who Met the Target",
    "2272. Substring With Largest Variance",
    "1157. Online Majority Element In Subarray",
    "48. Rotate Image",
    "1595. Minimum Cost to Connect Two Groups of Points",
    "516. Longest Palindromic Subsequence",
    "44. Wildcard Matching",
    "3092. Most Frequent IDs",
    "1659. Maximize Grid Happiness",
    "843. Guess the Word",
    "2266. Count Number of Texts",
    "1513. Number of Substrings With Only 1s",
    "2559. Count Vowel Strings in Ranges",
    "2091. Removing Minimum and Maximum From Array",
    "941. Valid Mountain Array",
    "1540. Can Convert String in K Moves",
    "47. Permutations II",
    "342. Power of Four",
    "96. Unique Binary Search Trees",
    "151. Reverse Words in a String",
    "1787. Make the XOR of All Segments Equal to Zero",
    "740. Delete and Earn",
    "845. Longest Mountain in Array",
    "2903. Find Indices With Index and Value Difference I",
    "424. Longest Repeating Character Replacement",
    "332. Reconstruct Itinerary",
    "719. Find K-th Smallest Pair Distance",
    "1002. Find Common Characters",
    "948. Bag of Tokens",
    "2681. Power of Heroes",
    "957. Prison Cells After N Days",
    "1819. Number of Different Subsequences GCDs",
    "927. Three Equal Parts",
    "1578. Minimum Time to Make Rope Colorful",
    "2954. Count the Number of Infection Sequences",
    "2438. Range Product Queries of Powers",
    "2555. Maximize Win From Two Segments",
    "662. Maximum Width of Binary Tree",
    "1750. Minimum Length of String After Deleting Similar Ends",
    "107. Binary Tree Level Order Traversal II",
    "2859. Sum of Values at Indices With K Set Bits",
    "2900. Longest Unequal Adjacent Groups Subsequence I",
    "21. Merge Two Sorted Lists",
    "2977. Minimum Cost to Convert String II",
    "768. Max Chunks To Make Sorted II",
    "2050. Parallel Courses III",
    "2750. Ways to Split Array Into Good Subarrays",
    "559. Maximum Depth of N-ary Tree",
    "450. Delete Node in a BST",
    "692. Top K Frequent Words",
    "3229. Minimum Operations to Make Array Equal to Target",
    "587. Erect the Fence",
    "485. Max Consecutive Ones",
    "2818. Apply Operations to Maximize Score",
    "3117. Minimum Sum of Values by Dividing Array",
    "447. Number of Boomerangs",
    "2279. Maximum Bags With Full Capacity of Rocks",
    "398. Random Pick Index",
    "59. Spiral Matrix II",
    "1808. Maximize Number of Nice Divisors",
    "1080. Insufficient Nodes in Root to Leaf Paths",
    "2601. Prime Subtraction Operation",
    "391. Perfect Rectangle",
    "525. Contiguous Array",
    "799. Champagne Tower",
    "1235. Maximum Profit in Job Scheduling",
    "1305. All Elements in Two Binary Search Trees",
    "3176. Find the Maximum Length of a Good Subsequence I",
    "509. Fibonacci Number",
    "1512. Number of Good Pairs",
    "1048. Longest String Chain",
    "2895. Minimum Processing Time",
    "953. Verifying an Alien Dictionary",
    "3185. Count Pairs That Form a Complete Day II",
    "1424. Diagonal Traverse II",
    "2106. Maximum Fruits Harvested After at Most K Steps",
    "50. Pow(x, n)",
    "1976. Number of Ways to Arrive at Destination",
    "3227. Vowels Game in a String",
    "2366. Minimum Replacements to Sort the Array",
    "1345. Jump Game IV",
    "1519. Number of Nodes in the Sub-Tree With the Same Label",
    "2861. Maximum Number of Alloys",
    "94. Binary Tree Inorder Traversal",
    "455. Assign Cookies",
    "2527. Find Xor-Beauty of Array",
    "956. Tallest Billboard",
    "682. Baseball Game",
    "1883. Minimum Skips to Arrive at Meeting On Time",
    "1247. Minimum Swaps to Make Strings Equal",
    "137. Single Number II",
    "324. Wiggle Sort II",
    "334. Increasing Triplet Subsequence",
    "1552. Magnetic Force Between Two Balls",
    "1004. Max Consecutive Ones III",
    "1734. Decode XORed Permutation",
    "2707. Extra Characters in a String",
    "2434. Using a Robot to Print the Lexicographically Smallest String",
    "167. Two Sum II - Input Array Is Sorted",
    "1024. Video Stitching",
    "1033. Moving Stones Until Consecutive",
    "1382. Balance a Binary Search Tree",
    "1583. Count Unhappy Friends",
    "922. Sort Array By Parity II",
    "2269. Find the K-Beauty of a Number",
    "2652. Sum Multiples",
    "2042. Check if Numbers Are Ascending in a Sentence",
    "18. 4Sum",
    "1329. Sort the Matrix Diagonally",
    "792. Number of Matching Subsequences",
    "440. K-th Smallest in Lexicographical Order",
    "3203. Find Minimum Diameter After Merging Two Trees",
    "85. Maximal Rectangle",
    "1859. Sorting the Sentence",
    "493. Reverse Pairs",
    "846. Hand of Straights",
    "2824. Count Pairs Whose Sum is Less than Target",
    "2973. Find Number of Coins to Place in Tree Nodes",
    "1482. Minimum Number of Days to Make m Bouquets",
    "641. Design Circular Deque",
    "1830. Minimum Number of Operations to Make String Sorted",
    "347. Top K Frequent Elements",
    "1560. Most Visited Sector in  a Circular Track",
    "1290. Convert Binary Number in a Linked List to Integer",
    "3067. Count Pairs of Connectable Servers in a Weighted Tree Network",
    "2288. Apply Discount to Prices",
    "229. Majority Element II",
    "1947. Maximum Compatibility Score Sum",
    "2068. Check Whether Two Strings are Almost Equivalent",
    "2218. Maximum Value of K Coins From Piles",
    "1381. Design a Stack With Increment Operation",
    "794. Valid Tic-Tac-Toe State",
    "1109. Corporate Flight Bookings",
    "202. Happy Number",
    "517. Super Washing Machines",
    "3134. Find the Median of the Uniqueness Array",
    "1504. Count Submatrices With All Ones",
    "2094. Finding 3-Digit Even Numbers",
    "850. Rectangle Area II",
    "329. Longest Increasing Path in a Matrix",
    "2642. Design Graph With Shortest Path Calculator",
    "1935. Maximum Number of Words You Can Type",
    "1306. Jump Game III",
    "684. Redundant Connection",
    "1673. Find the Most Competitive Subsequence",
    "80. Remove Duplicates from Sorted Array II",
    "1138. Alphabet Board Path",
    "857. Minimum Cost to Hire K Workers",
    "2407. Longest Increasing Subsequence II",
    "1779. Find Nearest Point That Has the Same X or Y Coordinate",
    "2645. Minimum Additions to Make Valid String",
    "1361. Validate Binary Tree Nodes",
    "111. Minimum Depth of Binary Tree",
    "2156. Find Substring With Given Hash Value",
    "135. Candy",
    "2086. Minimum Number of Food Buckets to Feed the Hamsters",
    "1465. Maximum Area of a Piece of Cake After Horizontal and Vertical Cuts",
    "2713. Maximum Strictly Increasing Cells in a Matrix",
    "839. Similar String Groups",
    "2468. Split Message Based on Limit",
    "2685. Count the Number of Complete Components",
    "2226. Maximum Candies Allocated to K Children",
    "3084. Count Substrings Starting and Ending with Given Character",
    "2212. Maximum Points in an Archery Competition",
    "2926. Maximum Balanced Subsequence Sum",
    "1187. Make Array Strictly Increasing",
    "2570. Merge Two 2D Arrays by Summing Values",
    "3012. Minimize Length of Array Using Operations",
    "1291. Sequential Digits",
    "2309. Greatest English Letter in Upper and Lower Case",
    "2733. Neither Minimum nor Maximum",
    "2415. Reverse Odd Levels of Binary Tree",
    "7. Reverse Integer",
    "712. Minimum ASCII Delete Sum for Two Strings",
    "646. Maximum Length of Pair Chain",
    "3082. Find the Sum of the Power of All Subsequences",
    "125. Valid Palindrome",
    "494. Target Sum",
    "410. Split Array Largest Sum",
    "3099. Harshad Number",
    "787. Cheapest Flights Within K Stops",
    "2671. Frequency Tracker",
    "1160. Find Words That Can Be Formed by Characters",
    "785. Is Graph Bipartite?",
    "2146. K Highest Ranked Items Within a Price Range",
    "1370. Increasing Decreasing String",
    "388. Longest Absolute File Path",
    "25. Reverse Nodes in k-Group",
    "2326. Spiral Matrix IV",
    "1905. Count Sub Islands",
    "676. Implement Magic Dictionary",
    "2122. Recover the Original Array",
    "2965. Find Missing and Repeated Values",
    "330. Patching Array",
    "1360. Number of Days Between Two Dates",
    "2243. Calculate Digit Sum of a String",
    "1600. Throne Inheritance",
    "2244. Minimum Rounds to Complete All Tasks",
    "1759. Count Number of Homogenous Substrings",
    "547. Number of Provinces",
    "2248. Intersection of Multiple Arrays",
    "942. DI String Match",
    "1240. Tiling a Rectangle with the Fewest Squares",
    "387. First Unique Character in a String",
    "2812. Find the Safest Path in a Grid",
    "3190. Find Minimum Operations to Make All Elements Divisible by Three",
    "2069. Walking Robot Simulation II",
    "2915. Length of the Longest Subsequence That Sums to Target",
    "2327. Number of People Aware of a Secret",
    "2470. Number of Subarrays With LCM Equal to K",
    "1368. Minimum Cost to Make at Least One Valid Path in a Grid",
    "979. Distribute Coins in Binary Tree",
    "1224. Maximum Equal Frequency",
    "2595. Number of Even and Odd Bits",
    "2769. Find the Maximum Achievable Number",
    "1385. Find the Distance Value Between Two Arrays",
    "865. Smallest Subtree with all the Deepest Nodes",
    "1359. Count All Valid Pickup and Delivery Options",
    "1206. Design Skiplist",
    "174. Dungeon Game",
    "739. Daily Temperatures",
    "263. Ugly Number",
    "2018. Check if Word Can Be Placed In Crossword",
    "2547. Minimum Cost to Split an Array",
    "807. Max Increase to Keep City Skyline",
    "2683. Neighboring Bitwise XOR",
    "2960. Count Tested Devices After Test Operations",
    "1219. Path with Maximum Gold",
    "2736. Maximum Sum Queries",
    "399. Evaluate Division",
    "2516. Take K of Each Character From Left and Right",
    "2081. Sum of k-Mirror Numbers",
    "119. Pascal's Triangle II",
    "2217. Find Palindrome With Fixed Length",
    "725. Split Linked List in Parts",
    "1807. Evaluate the Bracket Pairs of a String",
    "1604. Alert Using Same Key-Card Three or More Times in a One Hour Period",
    "1834. Single-Threaded CPU",
    "1353. Maximum Number of Events That Can Be Attended",
    "3225. Maximum Score From Grid Operations",
    "3254. Find the Power of K-Size Subarrays I",
    "1441. Build an Array With Stack Operations",
    "33. Search in Rotated Sorted Array",
    "3233. Find the Count of Numbers Which Are Not Special",
    "376. Wiggle Subsequence",
    "1416. Restore The Array",
    "2059. Minimum Operations to Convert Number",
    "1703. Minimum Adjacent Swaps for K Consecutive Ones",
    "885. Spiral Matrix III",
    "218. The Skyline Problem",
    "164. Maximum Gap",
    "1591. Strange Printer II",
    "54. Spiral Matrix",
    "835. Image Overlap",
    "539. Minimum Time Difference",
    "900. RLE Iterator",
    "1375. Number of Times Binary String Is Prefix-Aligned",
    "1553. Minimum Number of Days to Eat N Oranges",
    "661. Image Smoother",
    "1442. Count Triplets That Can Form Two Arrays of Equal XOR",
    "2799. Count Complete Subarrays in an Array",
    "2767. Partition String Into Minimum Beautiful Substrings",
    "482. License Key Formatting",
    "2390. Removing Stars From a String",
    "674. Longest Continuous Increasing Subsequence",
    "2529. Maximum Count of Positive Integer and Negative Integer",
    "987. Vertical Order Traversal of a Binary Tree",
    "2612. Minimum Reverse Operations",
    "235. Lowest Common Ancestor of a Binary Search Tree",
    "1326. Minimum Number of Taps to Open to Water a Garden",
    "2449. Minimum Number of Operations to Make Arrays Similar",
    "3107. Minimum Operations to Make Median of Array Equal to K",
    "3187. Peaks in Array",
    "1371. Find the Longest Substring Containing Vowels in Even Counts",
    "98. Validate Binary Search Tree",
    "995. Minimum Number of K Consecutive Bit Flips",
    "836. Rectangle Overlap",
    "1376. Time Needed to Inform All Employees",
    "1793. Maximum Score of a Good Subarray",
    "6. Zigzag Conversion",
    "481. Magical String",
    "2136. Earliest Possible Day of Full Bloom",
    "1647. Minimum Deletions to Make Character Frequencies Unique",
    "2791. Count Paths That Can Form a Palindrome in a Tree",
    "365. Water and Jug Problem",
    "82. Remove Duplicates from Sorted List II",
    "1955. Count Number of Special Subsequences",
    "2807. Insert Greatest Common Divisors in Linked List",
    "598. Range Addition II",
    "2662. Minimum Cost of a Path With Special Roads",
    "670. Maximum Swap",
    "2815. Max Pair Sum in an Array",
    "2058. Find the Minimum and Maximum Number of Nodes Between Critical Points",
    "920. Number of Music Playlists",
    "3049. Earliest Second to Mark Indices II",
    "796. Rotate String",
    "1838. Frequency of the Most Frequent Element",
    "1944. Number of Visible People in a Queue",
    "2257. Count Unguarded Cells in the Grid",
    "2231. Largest Number After Digit Swaps by Parity",
    "1861. Rotating the Box",
    "519. Random Flip Matrix",
    "1026. Maximum Difference Between Node and Ancestor",
    "3077. Maximum Strength of K Disjoint Subarrays",
    "1979. Find Greatest Common Divisor of Array",
    "466. Count The Repetitions",
    "3097. Shortest Subarray With OR at Least K II",
    "1411. Number of Ways to Paint N × 3 Grid",
    "594. Longest Harmonious Subsequence",
    "3001. Minimum Moves to Capture The Queen",
    "677. Map Sum Pairs",
    "131. Palindrome Partitioning",
    "1263. Minimum Moves to Move a Box to Their Target Location",
    "1169. Invalid Transactions",
    "1449. Form Largest Integer With Digits That Add up to Target",
    "454. 4Sum II",
    "1646. Get Maximum in Generated Array",
    "1295. Find Numbers with Even Number of Digits",
    "2917. Find the K-or of an Array",
    "970. Powerful Integers",
    "1823. Find the Winner of the Circular Game",
    "1707. Maximum XOR With an Element From Array",
    "2014. Longest Subsequence Repeated k Times",
    "1498. Number of Subsequences That Satisfy the Given Sum Condition",
    "2183. Count Array Pairs Divisible by K",
    "1585. Check If String Is Transformable With Substring Sort Operations",
    "1997. First Day Where You Have Been in All the Rooms",
    "3184. Count Pairs That Form a Complete Day I",
    "2011. Final Value of Variable After Performing Operations",
    "2200. Find All K-Distant Indices in an Array",
    "971. Flip Binary Tree To Match Preorder Traversal",
    "1781. Sum of Beauty of All Substrings",
    "2452. Words Within Two Edits of Dictionary",
    "567. Permutation in String",
    "657. Robot Return to Origin",
    "1668. Maximum Repeating Substring",
    "1619. Mean of Array After Removing Some Elements",
    "996. Number of Squareful Arrays",
    "1492. The kth Factor of n",
    "2124. Check if All A's Appears Before All B's",
    "149. Max Points on a Line",
    "1089. Duplicate Zeros",
    "2741. Special Permutations",
    "337. House Robber III",
    "2343. Query Kth Smallest Trimmed Number",
    "2281. Sum of Total Strength of Wizards",
    "2670. Find the Distinct Difference Array",
    "1678. Goal Parser Interpretation",
    "3036. Number of Subarrays That Match a Pattern II",
    "2063. Vowels of All Substrings",
    "2761. Prime Pairs With Target Sum",
    "997. Find the Town Judge",
    "1996. The Number of Weak Characters in the Game",
    "523. Continuous Subarray Sum",
    "2359. Find Closest Node to Given Two Nodes",
    "130. Surrounded Regions",
    "834. Sum of Distances in Tree",
    "611. Valid Triangle Number",
    "1178. Number of Valid Words for Each Puzzle",
    "701. Insert into a Binary Search Tree",
    "1748. Sum of Unique Elements",
    "2603. Collect Coins in a Tree",
    "1611. Minimum One Bit Operations to Make Integers Zero",
    "2456. Most Popular Video Creator",
    "72. Edit Distance",
    "1632. Rank Transform of a Matrix",
    "2402. Meeting Rooms III",
    "2383. Minimum Hours of Training to Win a Competition",
    "3197. Find the Minimum Area to Cover All Ones II",
    "736. Parse Lisp Expression",
    "2012. Sum of Beauty in the Array",
    "664. Strange Printer",
    "1417. Reformat The String",
    "1034. Coloring A Border",
    "2032. Two Out of Three",
    "495. Teemo Attacking",
    "2441. Largest Positive Integer That Exists With Its Negative",
    "1494. Parallel Courses II",
    "1192. Critical Connections in a Network",
    "1886. Determine Whether Matrix Can Be Obtained By Rotation",
    "2328. Number of Increasing Paths in a Grid",
    "1899. Merge Triplets to Form Target Triplet",
    "451. Sort Characters By Frequency",
    "420. Strong Password Checker",
    "134. Gas Station",
    "2873. Maximum Value of an Ordered Triplet I",
    "457. Circular Array Loop",
    "201. Bitwise AND of Numbers Range",
    "2611. Mice and Cheese",
    "1018. Binary Prefix Divisible By 5",
    "2278. Percentage of Letter in String",
    "978. Longest Turbulent Subarray",
    "2963. Count the Number of Good Partitions",
    "685. Redundant Connection II",
    "2053. Kth Distinct String in an Array",
    "103. Binary Tree Zigzag Level Order Traversal",
    "3201. Find the Maximum Length of Valid Subsequence I",
    "1856. Maximum Subarray Min-Product",
    "2141. Maximum Running Time of N Computers",
    "1775. Equal Sum Arrays With Minimum Number of Operations",
    "1616. Split Two Strings to Make Palindrome",
    "2913. Subarrays Distinct Element Sum of Squares I",
    "1930. Unique Length-3 Palindromic Subsequences",
    "1016. Binary String With Substrings Representing 1 To N",
    "524. Longest Word in Dictionary through Deleting",
    "3251. Find the Count of Monotonic Pairs II",
    "565. Array Nesting",
    "3101. Count Alternating Subarrays",
    "1386. Cinema Seat Allocation",
    "1081. Smallest Subsequence of Distinct Characters",
    "2747. Count Zero Request Servers",
    "886. Possible Bipartition",
    "56. Merge Intervals",
    "2911. Minimum Changes to Make K Semi-palindromes",
    "639. Decode Ways II",
    "3235. Check if the Rectangle Corner Is Reachable",
    "1970. Last Day Where You Can Still Cross",
    "36. Valid Sudoku",
    "1870. Minimum Speed to Arrive on Time",
    "2035. Partition Array Into Two Arrays to Minimize Sum Difference",
    "198. House Robber",
    "1357. Apply Discount Every n Orders",
    "1499. Max Value of Equation",
    "1872. Stone Game VIII",
    "2049. Count Nodes With the Highest Score",
    "225. Implement Stack using Queues",
    "3010. Divide an Array Into Subarrays With Minimum Cost I",
    "1254. Number of Closed Islands",
    "2155. All Divisions With the Highest Score of a Binary Array",
    "1073. Adding Two Negabinary Numbers",
    "2318. Number of Distinct Roll Sequences",
    "1655. Distribute Repeating Integers",
    "1744. Can You Eat Your Favorite Candy on Your Favorite Day?",
    "1998. GCD Sort of an Array",
    "2379. Minimum Recolors to Get K Consecutive Black Blocks",
    "976. Largest Perimeter Triangle",
    "728. Self Dividing Numbers",
    "860. Lemonade Change",
    "1250. Check If It Is a Good Array",
    "946. Validate Stack Sequences",
    "791. Custom Sort String",
    "1437. Check If All 1's Are at Least Length K Places Away",
    "214. Shortest Palindrome",
    "2587. Rearrange Array to Maximize Prefix Score",
    "1200. Minimum Absolute Difference",
    "1806. Minimum Number of Operations to Reinitialize a Permutation",
    "155. Min Stack",
    "2295. Replace Elements in an Array",
    "1925. Count Square Sum Triples",
    "1471. The k Strongest Values in an Array",
    "2517. Maximum Tastiness of Candy Basket",
    "128. Longest Consecutive Sequence",
    "240. Search a 2D Matrix II",
    "721. Accounts Merge",
    "1314. Matrix Block Sum",
    "1027. Longest Arithmetic Subsequence",
    "1784. Check if Binary String Has at Most One Segment of Ones",
    "882. Reachable Nodes In Subdivided Graph",
    "2391. Minimum Amount of Time to Collect Garbage",
    "710. Random Pick with Blacklist",
    "1753. Maximum Score From Removing Stones",
    "1311. Get Watched Videos by Your Friends",
    "1802. Maximum Value at a Given Index in a Bounded Array",
    "2119. A Number After a Double Reversal",
    "71. Simplify Path",
    "491. Non-decreasing Subsequences",
    "2966. Divide Array Into Arrays With Max Difference",
    "1887. Reduction Operations to Make the Array Elements Equal",
    "313. Super Ugly Number",
    "1144. Decrease Elements To Make Array Zigzag",
    "343. Integer Break",
    "2369. Check if There is a Valid Partition For The Array",
    "2961. Double Modular Exponentiation",
    "1137. N-th Tribonacci Number",
    "2765. Longest Alternating Subarray",
    "2333. Minimum Sum of Squared Difference",
    "1023. Camelcase Matching",
    "2197. Replace Non-Coprime Numbers in Array",
    "2302. Count Subarrays With Score Less Than K",
    "786. K-th Smallest Prime Fraction",
    "761. Special Binary String",
    "2057. Smallest Index With Equal Value",
    "2646. Minimize the Total Price of the Trips",
    "2157. Groups of Strings",
    "1147. Longest Chunked Palindrome Decomposition",
    "775. Global and Local Inversions",
    "892. Surface Area of 3D Shapes",
    "1857. Largest Color Value in a Directed Graph",
    "2481. Minimum Cuts to Divide a Circle",
    "2641. Cousins in Binary Tree II",
    "1092. Shortest Common Supersequence ",
    "1095. Find in Mountain Array",
    "53. Maximum Subarray",
    "915. Partition Array into Disjoint Intervals",
    "2224. Minimum Number of Operations to Convert Time",
    "2087. Minimum Cost Homecoming of a Robot in a Grid",
    "757. Set Intersection Size At Least Two",
    "2101. Detonate the Maximum Bombs",
    "2235. Add Two Integers",
    "2684. Maximum Number of Moves in a Grid",
    "3169. Count Days Without Meetings",
    "2350. Shortest Impossible Sequence of Rolls",
    "39. Combination Sum",
    "2349. Design a Number Container System",
    "2790. Maximum Number of Groups With Increasing Length",
    "203. Remove Linked List Elements",
    "2428. Maximum Sum of an Hourglass",
    "12. Integer to Roman",
    "1317. Convert Integer to the Sum of Two No-Zero Integers",
    "1043. Partition Array for Maximum Sum",
    "2038. Remove Colored Pieces if Both Neighbors are the Same Color",
    "1044. Longest Duplicate Substring",
    "95. Unique Binary Search Trees II",
    "2560. House Robber IV",
    "117. Populating Next Right Pointers in Each Node II",
    "1960. Maximum Product of the Length of Two Palindromic Substrings",
    "2271. Maximum White Tiles Covered by a Carpet",
    "3028. Ant on the Boundary",
    "2833. Furthest Point From Origin",
    "3016. Minimum Number of Pushes to Type Word II",
    "3192. Minimum Operations to Make Binary Array Elements Equal to One II",
    "1486. XOR Operation in an Array",
    "257. Binary Tree Paths",
    "1929. Concatenation of Array",
    "1588. Sum of All Odd Length Subarrays",
    "1466. Reorder Routes to Make All Paths Lead to the City Zero",
    "2561. Rearranging Fruits",
    "3139. Minimum Cost to Equalize Array",
    "2939. Maximum Xor Product",
    "101. Symmetric Tree",
    "1685. Sum of Absolute Differences in a Sorted Array",
    "2166. Design Bitset",
    "2553. Separate the Digits in an Array",
    "1829. Maximum XOR for Each Query",
    "118. Pascal's Triangle",
    "1422. Maximum Score After Splitting a String",
    "583. Delete Operation for Two Strings",
    "2526. Find Consecutive Integers from a Data Stream",
    "97. Interleaving String",
    "2730. Find the Longest Semi-Repetitive Substring",
    "84. Largest Rectangle in Histogram",
    "2575. Find the Divisibility Array of a String",
    "462. Minimum Moves to Equal Array Elements II",
    "2478. Number of Beautiful Partitions",
    "1497. Check If Array Pairs Are Divisible by k",
    "1920. Build Array from Permutation",
    "2905. Find Indices With Index and Value Difference II",
    "99. Recover Binary Search Tree",
    "216. Combination Sum III",
    "1991. Find the Middle Index in Array",
    "2530. Maximal Score After Applying K Operations",
    "1992. Find All Groups of Farmland",
    "2154. Keep Multiplying Found Values by Two",
    "869. Reordered Power of 2",
    "217. Contains Duplicate",
    "108. Convert Sorted Array to Binary Search Tree",
    "2286. Booking Concert Tickets in Groups",
    "782. Transform to Chessboard",
    "3113. Find the Number of Subarrays Where Boundary Elements Are Maximum",
    "2579. Count Total Number of Colored Cells",
    "2440. Create Components With Same Value",
    "2841. Maximum Sum of Almost Unique Subarray",
    "1863. Sum of All Subset XOR Totals",
    "537. Complex Number Multiplication",
    "929. Unique Email Addresses",
    "3210. Find the Encrypted String",
    "2398. Maximum Number of Robots Within Budget",
    "2019. The Score of Students Solving Math Expression",
    "2597. The Number of Beautiful Subsets",
    "1096. Brace Expansion II",
    "1590. Make Sum Divisible by P",
    "3245. Alternating Groups III",
    "2643. Row With Maximum Ones",
    "3248. Snake in Matrix",
    "2760. Longest Even Odd Subarray With Threshold",
    "384. Shuffle an Array",
    "273. Integer to English Words",
    "2509. Cycle Length Queries in a Tree",
    "1981. Minimize the Difference Between Target and Chosen Elements",
    "2614. Prime In Diagonal",
    "508. Most Frequent Subtree Sum",
    "141. Linked List Cycle",
    "1030. Matrix Cells in Distance Order",
    "2482. Difference Between Ones and Zeros in Row and Column",
    "2169. Count Operations to Obtain Zero",
    "1299. Replace Elements with Greatest Element on Right Side",
    "3145. Find Products of Elements of Big Array",
    "2931. Maximum Spending After Buying Items",
    "998. Maximum Binary Tree II",
    "1803. Count Pairs With XOR in a Range",
    "3011. Find if Array Can Be Sorted",
    "2946. Matrix Similarity After Cyclic Shifts",
    "3174. Clear Digits",
    "1932. Merge BSTs to Create Single BST",
    "3242. Design Neighbor Sum Service",
    "899. Orderly Queue",
    "153. Find Minimum in Rotated Sorted Array",
    "2160. Minimum Sum of Four Digit Number After Splitting Digits",
    "2001. Number of Pairs of Interchangeable Rectangles",
    "653. Two Sum IV - Input is a BST",
    "2801. Count Stepping Numbers in Range",
    "2196. Create Binary Tree From Descriptions",
    "733. Flood Fill",
    "19. Remove Nth Node From End of List",
    "1648. Sell Diminishing-Valued Colored Balls",
    "2138. Divide a String Into Groups of Size k",
    "1675. Minimize Deviation in Array",
    "1912. Design Movie Rental System",
    "162. Find Peak Element",
    "152. Maximum Product Subarray",
    "2592. Maximize Greatness of an Array",
    "530. Minimum Absolute Difference in BST",
    "3066. Minimum Operations to Exceed Threshold Value II",
    "1792. Maximum Average Pass Ratio",
    "2460. Apply Operations to an Array",
    "1815. Maximum Number of Groups Getting Fresh Donuts",
    "1005. Maximize Sum Of Array After K Negations",
    "1396. Design Underground System",
    "417. Pacific Atlantic Water Flow",
    "907. Sum of Subarray Minimums",
    "3025. Find the Number of Ways to Place People I",
    "75. Sort Colors",
    "1755. Closest Subsequence Sum",
    "959. Regions Cut By Slashes",
    "581. Shortest Unsorted Continuous Subarray",
    "2808. Minimum Seconds to Equalize a Circular Array",
    "312. Burst Balloons",
    "1984. Minimum Difference Between Highest and Lowest of K Scores",
    "415. Add Strings",
    "1301. Number of Paths with Max Score",
    "872. Leaf-Similar Trees",
    "2285. Maximum Total Importance of Roads",
    "2857. Count Pairs of Points With Distance k",
    "2321. Maximum Score Of Spliced Array",
    "3165. Maximum Sum of Subsequence With Non-adjacent Elements",
    "1234. Replace the Substring for Balanced String",
    "749. Contain Virus",
    "814. Binary Tree Pruning",
    "2255. Count Prefixes of a Given String",
    "2447. Number of Subarrays With GCD Equal to K",
    "264. Ugly Number II",
    "3222. Find the Winning Player in Coin Game",
    "985. Sum of Even Numbers After Queries",
    "2610. Convert an Array Into a 2D Array With Conditions",
    "1854. Maximum Population Year",
    "300. Longest Increasing Subsequence",
    "2594. Minimum Time to Repair Cars",
    "2581. Count Number of Possible Root Nodes",
    "227. Basic Calculator II",
    "1922. Count Good Numbers",
    "1941. Check if All Characters Have Equal Number of Occurrences",
    "105. Construct Binary Tree from Preorder and Inorder Traversal",
    "1770. Maximum Score from Performing Multiplication Operations",
    "1710. Maximum Units on a Truck",
    "2541. Minimum Operations to Make Array Equal II",
    "1111. Maximum Nesting Depth of Two Valid Parentheses Strings",
    "2163. Minimum Difference in Sums After Removal of Elements",
    "2207. Maximize Number of Subsequences in a String",
    "1825. Finding MK Average",
    "1701. Average Waiting Time",
    "650. 2 Keys Keyboard",
    "566. Reshape the Matrix",
    "478. Generate Random Point in a Circle",
    "715. Range Module",
    "1123. Lowest Common Ancestor of Deepest Leaves",
    "1688. Count of Matches in Tournament",
    "793. Preimage Size of Factorial Zeroes Function",
    "2734. Lexicographically Smallest String After Substring Operation",
    "413. Arithmetic Slices",
    "908. Smallest Range I",
    "2130. Maximum Twin Sum of a Linked List",
    "1328. Break a Palindrome",
    "467. Unique Substrings in Wraparound String",
    "2528. Maximize the Minimum Powered City",
    "1839. Longest Substring Of All Vowels in Order",
    "2334. Subarray With Elements Greater Than Varying Threshold",
    "2283. Check if Number Has Equal Digit Count and Digit Value",
    "1728. Cat and Mouse II",
    "861. Score After Flipping Matrix",
    "2501. Longest Square Streak in an Array",
    "1551. Minimum Operations to Make Array Equal",
    "304. Range Sum Query 2D - Immutable",
    "116. Populating Next Right Pointers in Each Node",
    "1791. Find Center of Star Graph",
    "338. Counting Bits",
    "2545. Sort the Students by Their Kth Score",
    "1037. Valid Boomerang",
    "374. Guess Number Higher or Lower",
    "2600. K Items With the Maximum Sum",
    "1573. Number of Ways to Split a String",
    "2151. Maximum Good People Based on Statements",
    "2121. Intervals Between Identical Elements",
    "2462. Total Cost to Hire K Workers",
    "2488. Count Subarrays With Median K",
    "1334. Find the City With the Smallest Number of Neighbors at a Threshold Distance",
    "777. Swap Adjacent in LR String",
    "1739. Building Boxes",
    "2849. Determine if a Cell Is Reachable at a Given Time",
    "526. Beautiful Arrangement",
    "2389. Longest Subsequence With Limited Sum",
    "129. Sum Root to Leaf Numbers",
    "2661. First Completely Painted Row or Column",
    "2980. Check if Bitwise OR Has Trailing Zeros",
    "126. Word Ladder II",
    "994. Rotting Oranges",
    "1909. Remove One Element to Make the Array Strictly Increasing",
    "106. Construct Binary Tree from Inorder and Postorder Traversal",
    "1610. Maximum Number of Visible Points",
    "231. Power of Two",
    "3015. Count the Number of Houses at a Certain Distance I",
    "849. Maximize Distance to Closest Person",
    "1530. Number of Good Leaf Nodes Pairs",
    "1162. As Far from Land as Possible",
    "2044. Count Number of Maximum Bitwise-OR Subsets",
    "2414. Length of the Longest Alphabetical Continuous Substring",
    "1763. Longest Nice Substring",
    "375. Guess Number Higher or Lower II",
    "2605. Form Smallest Number From Two Digit Arrays",
    "1926. Nearest Exit from Entrance in Maze",
    "1769. Minimum Number of Operations to Move All Balls to Each Box",
    "1300. Sum of Mutated Array Closest to Target",
    "1627. Graph Connectivity With Threshold",
    "724. Find Pivot Index",
    "599. Minimum Index Sum of Two Lists",
    "1987. Number of Unique Good Subsequences",
    "2325. Decode the Message",
    "1752. Check if Array Is Sorted and Rotated",
    "1473. Paint House III",
    "3074. Apple Redistribution into Boxes",
    "2680. Maximum OR",
    "274. H-Index",
    "345. Reverse Vowels of a String",
    "2342. Max Sum of a Pair With Equal Sum of Digits",
    "921. Minimum Add to Make Parentheses Valid",
    "1945. Sum of Digits of String After Convert",
    "1670. Design Front Middle Back Queue",
    "1140. Stone Game II",
    "2114. Maximum Number of Words Found in Sentences",
    "3071. Minimum Operations to Write the Letter Y on a Grid",
    "392. Is Subsequence",
    "2208. Minimum Operations to Halve Array Sum",
    "3241. Time Taken to Mark All Nodes",
    "136. Single Number",
    "546. Remove Boxes",
    "980. Unique Paths III",
    "1725. Number Of Rectangles That Can Form The Largest Square",
    "1712. Ways to Split Array Into Three Subarrays",
    "2663. Lexicographically Smallest Beautiful String",
    "1689. Partitioning Into Minimum Number Of Deci-Binary Numbers",
    "3193. Count the Number of Inversions",
    "1629. Slowest Key",
    "2335. Minimum Amount of Time to Fill Cups",
    "1840. Maximum Building Height",
    "1332. Remove Palindromic Subsequences",
    "150. Evaluate Reverse Polish Notation",
    "2551. Put Marbles in Bags",
    "2009. Minimum Number of Operations to Make Array Continuous",
    "1323. Maximum 69 Number",
    "2028. Find Missing Observations",
    "934. Shortest Bridge",
    "513. Find Bottom Left Tree Value",
    "779. K-th Symbol in Grammar",
    "60. Permutation Sequence",
    "442. Find All Duplicates in an Array",
    "2778. Sum of Squares of Special Elements ",
    "1079. Letter Tile Possibilities",
    "943. Find the Shortest Superstring",
    "1401. Circle and Rectangle Overlapping",
    "2312. Selling Pieces of Wood",
    "62. Unique Paths",
    "2187. Minimum Time to Complete Trips",
    "382. Linked List Random Node",
    "1286. Iterator for Combination",
    "172. Factorial Trailing Zeroes",
    "3030. Find the Grid of Region Average",
    "287. Find the Duplicate Number",
    "1776. Car Fleet II",
    "2598. Smallest Missing Non-negative Integer After Operations",
    "2503. Maximum Number of Points From Grid Queries",
    "2827. Number of Beautiful Integers in the Range",
    "2656. Maximum Sum With Exactly K Elements ",
    "2338. Count the Number of Ideal Arrays",
    "1105. Filling Bookcase Shelves",
    "705. Design HashSet",
    "763. Partition Labels",
    "1020. Number of Enclaves",
    "8. String to Integer (atoi)",
    "1202. Smallest String With Swaps",
    "140. Word Break II",
    "986. Interval List Intersections",
    "1185. Day of the Week",
    "460. LFU Cache",
    "2875. Minimum Size Subarray in Infinite Array",
    "636. Exclusive Time of Functions",
    "215. Kth Largest Element in an Array",
    "1443. Minimum Time to Collect All Apples in a Tree",
    "2839. Check if Strings Can be Made Equal With Operations I",
    "1302. Deepest Leaves Sum",
    "2454. Next Greater Element IV",
    "2399. Check Distances Between Same Letters",
    "221. Maximal Square",
    "476. Number Complement",
    "45. Jump Game II",
    "3093. Longest Common Suffix Queries",
    "3000. Maximum Area of Longest Diagonal Rectangle",
    "522. Longest Uncommon Subsequence II",
    "2496. Maximum Value of a String in an Array",
    "2322. Minimum Score After Removals on a Tree",
    "2165. Smallest Value of the Rearranged Number",
    "3122. Minimum Number of Operations to Satisfy Conditions",
    "1579. Remove Max Number of Edges to Keep Graph Fully Traversable",
    "2043. Simple Bank System",
    "1451. Rearrange Words in a Sentence",
    "1125. Smallest Sufficient Team",
    "1785. Minimum Elements to Add to Form a Given Sum",
    "2265. Count Nodes Equal to Average of Subtree",
    "1780. Check if Number is a Sum of Powers of Three",
    "1705. Maximum Number of Eaten Apples",
    "2425. Bitwise XOR of All Pairings",
    "1260. Shift 2D Grid",
    "696. Count Binary Substrings",
    "2867. Count Valid Paths in a Tree",
    "918. Maximum Sum Circular Subarray",
    "1567. Maximum Length of Subarray With Positive Product",
    "1786. Number of Restricted Paths From First to Last Node",
    "3007. Maximum Number That Sum of the Prices Is Less Than or Equal to K",
    "429. N-ary Tree Level Order Traversal",
    "647. Palindromic Substrings",
    "3244. Shortest Distance After Road Addition Queries II",
    "1221. Split a String in Balanced Strings",
    "2080. Range Frequency Queries",
    "2938. Separate Black and White Balls",
    "2745. Construct the Longest New String",
    "2731. Movement of Robots",
    "881. Boats to Save People",
    "1011. Capacity To Ship Packages Within D Days",
    "2711. Difference of Number of Distinct Values on Diagonals",
    "797. All Paths From Source to Target",
    "699. Falling Squares",
    "1798. Maximum Number of Consecutive Values You Can Make",
    "961. N-Repeated Element in Size 2N Array",
    "3040. Maximum Number of Operations With the Same Score II",
    "2401. Longest Nice Subarray",
    "3068. Find the Maximum Sum of Node Values",
    "2710. Remove Trailing Zeros From a String",
    "1189. Maximum Number of Balloons",
    "1001. Grid Illumination",
    "808. Soup Servings",
    "3043. Find the Length of the Longest Common Prefix",
    "1514. Path with Maximum Probability",
    "2045. Second Minimum Time to Reach Destination",
    "102. Binary Tree Level Order Traversal",
    "2241. Design an ATM Machine",
    "1957. Delete Characters to Make Fancy String",
    "693. Binary Number with Alternating Bits",
    "220. Contains Duplicate III",
    "477. Total Hamming Distance",
    "1015. Smallest Integer Divisible by K",
    "3006. Find Beautiful Indices in the Given Array I",
    "1330. Reverse Subarray To Maximize Array Value",
    "2572. Count the Number of Square-Free Subsets",
    "441. Arranging Coins",
    "389. Find the Difference",
    "2003. Smallest Missing Genetic Value in Each Subtree",
    "1284. Minimum Number of Flips to Convert Binary Matrix to Zero Matrix",
    "1606. Find Servers That Handled Most Number of Requests",
    "1191. K-Concatenation Maximum Sum",
    "1110. Delete Nodes And Return Forest",
    "686. Repeated String Match",
    "1509. Minimum Difference Between Largest and Smallest Value in Three Moves",
    "1625. Lexicographically Smallest String After Applying Operations",
    "2584. Split the Array to Make Coprime Products",
    "1742. Maximum Number of Balls in a Box",
    "1545. Find Kth Bit in Nth Binary String",
    "241. Different Ways to Add Parentheses",
    "295. Find Median from Data Stream",
    "2116. Check if a Parentheses String Can Be Valid",
    "1172. Dinner Plate Stacks",
    "967. Numbers With Same Consecutive Differences",
    "459. Repeated Substring Pattern",
    "1470. Shuffle the Array",
    "2040. Kth Smallest Product of Two Sorted Arrays",
    "2942. Find Words Containing Character",
    "811. Subdomain Visit Count",
    "2332. The Latest Time to Catch a Bus",
    "2129. Capitalize the Title",
    "3239. Minimum Number of Flips to Make Binary Grid Palindromic I",
    "2929. Distribute Candies Among Children II",
    "3111. Minimum Rectangles to Cover Points",
    "672. Bulb Switcher II",
    "2250. Count Number of Rectangles Containing Each Point",
    "2144. Minimum Cost of Buying Candies With Discount",
    "383. Ransom Note",
    "2500. Delete Greatest Value in Each Row",
    "1458. Max Dot Product of Two Subsequences",
    "26. Remove Duplicates from Sorted Array",
    "1518. Water Bottles",
    "1897. Redistribute Characters to Make All Strings Equal",
    "396. Rotate Function",
    "945. Minimum Increment to Make Array Unique",
    "853. Car Fleet",
    "2232. Minimize Result by Adding Parentheses to Expression",
    "1971. Find if Path Exists in Graph",
    "2064. Minimized Maximum of Products Distributed to Any Store",
    "2972. Count the Number of Incremovable Subarrays II",
    "769. Max Chunks To Make Sorted",
    "2748. Number of Beautiful Pairs",
    "1855. Maximum Distance Between a Pair of Values",
    "37. Sudoku Solver",
    "2531. Make Number of Distinct Characters Equal",
    "1282. Group the People Given the Group Size They Belong To",
    "2176. Count Equal and Divisible Pairs in an Array",
    "1418. Display Table of Food Orders in a Restaurant",
    "1680. Concatenation of Consecutive Binary Numbers",
    "406. Queue Reconstruction by Height",
    "2466. Count Ways To Build Good Strings",
    "870. Advantage Shuffle",
    "2348. Number of Zero-Filled Subarrays",
    "1640. Check Array Formation Through Concatenation",
    "2472. Maximum Number of Non-overlapping Palindrome Substrings",
    "2331. Evaluate Boolean Binary Tree",
    "1021. Remove Outermost Parentheses",
    "127. Word Ladder",
    "188. Best Time to Buy and Sell Stock IV",
    "928. Minimize Malware Spread II",
    "3090. Maximum Length Substring With Two Occurrences",
    "798. Smallest Rotation with Highest Score",
    "506. Relative Ranks",
    "1390. Four Divisors",
    "2925. Maximum Score After Applying Operations on a Tree",
    "474. Ones and Zeroes",
    "2195. Append K Integers With Minimal Sum",
    "3162. Find the Number of Good Pairs I",
    "2430. Maximum Deletions on a String",
    "2433. Find The Original Array of Prefix Xor",
    "887. Super Egg Drop",
    "1124. Longest Well-Performing Interval",
    "290. Word Pattern",
    "114. Flatten Binary Tree to Linked List",
    "2486. Append Characters to String to Make Subsequence",
    "2088. Count Fertile Pyramids in a Land",
    "3038. Maximum Number of Operations With the Same Score I",
    "492. Construct the Rectangle",
    "2578. Split With Minimum Sum",
    "2354. Number of Excellent Pairs",
    "2515. Shortest Distance to Target String in a Circular Array",
    "2934. Minimum Operations to Maximize Last Elements in Arrays",
    "1738. Find Kth Largest XOR Coordinate Value",
    "767. Reorganize String",
    "1186. Maximum Subarray Sum with One Deletion",
    "789. Escape The Ghosts",
    "3211. Generate Binary Strings Without Adjacent Zeros",
    "3208. Alternating Groups II",
    "947. Most Stones Removed with Same Row or Column",
    "110. Balanced Binary Tree",
    "1626. Best Team With No Conflicts",
    "2111. Minimum Operations to Make the Array K-Increasing",
    "2564. Substring XOR Queries",
    "191. Number of 1 Bits",
    "977. Squares of a Sorted Array",
    "629. K Inverse Pairs Array",
    "10. Regular Expression Matching",
    "1233. Remove Sub-Folders from the Filesystem",
    "1704. Determine if String Halves Are Alike",
    "1866. Number of Ways to Rearrange Sticks With K Sticks Visible",
    "1071. Greatest Common Divisor of Strings",
    "3019. Number of Changing Keys",
    "824. Goat Latin",
    "2029. Stone Game IX",
    "1383. Maximum Performance of a Team",
    "630. Course Schedule III",
    "2784. Check if Array is Good",
    "3081. Replace Question Marks in String to Minimize Its Value",
    "2983. Palindrome Rearrangement Queries",
    "1171. Remove Zero Sum Consecutive Nodes from Linked List",
    "1766. Tree of Coprimes",
    "2485. Find the Pivot Integer",
    "2076. Process Restricted Friend Requests",
    "2810. Faulty Keyboard",
    "2375. Construct Smallest Number From DI String",
    "2315. Count Asterisks",
    "1865. Finding Pairs With a Certain Sum",
    "1952. Three Divisors",
    "446. Arithmetic Slices II - Subsequence",
    "3234. Count the Number of Substrings With Dominant Ones",
    "1210. Minimum Moves to Reach Target with Rotations",
    "762. Prime Number of Set Bits in Binary Representation",
    "2413. Smallest Even Multiple",
    "1621. Number of Sets of K Non-Overlapping Line Segments",
    "46. Permutations",
    "1735. Count Ways to Make Array With Product",
    "1938. Maximum Genetic Difference Query",
    "1450. Number of Students Doing Homework at a Given Time",
    "3179. Find the N-th Value After K Seconds",
    "2. Add Two Numbers",
    "1104. Path In Zigzag Labelled Binary Tree",
    "260. Single Number III",
    "3218. Minimum Cost for Cutting Cake I",
    "822. Card Flipping Game",
    "2542. Maximum Subsequence Score",
    "1637. Widest Vertical Area Between Two Points Containing No Points",
    "707. Design Linked List",
    "1614. Maximum Nesting Depth of the Parentheses",
    "3226. Number of Bit Changes to Make Two Integers Equal",
    "2948. Make Lexicographically Smallest Array by Swapping Elements",
    "1884. Egg Drop With 2 Eggs and N Floors",
    "74. Search a 2D Matrix",
    "1959. Minimum Total Space Wasted With K Resizing Operations",
    "1894. Find the Student that Will Replace the Chalk",
    "1131. Maximum of Absolute Value Expression",
    "2293. Min Max Game",
    "498. Diagonal Traverse",
    "1732. Find the Highest Altitude",
    "1281. Subtract the Product and Sum of Digits of an Integer",
    "3100. Water Bottles II",
    "1608. Special Array With X Elements Greater Than or Equal X",
    "819. Most Common Word",
    "2264. Largest 3-Same-Digit Number in String",
    "687. Longest Univalue Path",
    "1094. Car Pooling",
    "1687. Delivering Boxes from Storage to Ports",
    "1155. Number of Dice Rolls With Target Sum",
    "1847. Closest Room",
    "621. Task Scheduler",
    "3008. Find Beautiful Indices in the Given Array II",
    "764. Largest Plus Sign",
    "3123. Find Edges in Shortest Paths",
    "1007. Minimum Domino Rotations For Equal Row",
    "2404. Most Frequent Even Element",
    "593. Valid Square",
    "905. Sort Array By Parity",
    "560. Subarray Sum Equals K",
    "2131. Longest Palindrome by Concatenating Two Letter Words",
    "120. Triangle",
    "2027. Minimum Moves to Convert String",
    "2919. Minimum Increment Operations to Make Array Beautiful",
    "858. Mirror Reflection",
    "2596. Check Knight Tour Configuration",
    "3027. Find the Number of Ways to Place People II",
    "1906. Minimum Absolute Difference Queries",
    "2074. Reverse Nodes in Even Length Groups",
    "817. Linked List Components",
    "3. Longest Substring Without Repeating Characters",
    "2576. Find the Maximum Number of Marked Indices",
    "2360. Longest Cycle in a Graph",
    "876. Middle of the Linked List",
    "645. Set Mismatch",
    "2210. Count Hills and Valleys in an Array",
    "2140. Solving Questions With Brainpower",
    "1404. Number of Steps to Reduce a Number in Binary Representation to One",
    "521. Longest Uncommon Subsequence I",
    "2170. Minimum Operations to Make the Array Alternating",
    "2498. Frog Jump II",
    "1405. Longest Happy String",
    "951. Flip Equivalent Binary Trees",
    "816. Ambiguous Coordinates",
    "1395. Count Number of Teams",
    "1726. Tuple with Same Product",
    "3014. Minimum Number of Pushes to Type Word I",
    "38. Count and Say",
    "1074. Number of Submatrices That Sum to Target",
    "2007. Find Original Array From Doubled Array",
    "2523. Closest Prime Numbers in Range",
    "1019. Next Greater Node In Linked List",
    "2858. Minimum Edge Reversals So Every Node Is Reachable",
    "268. Missing Number",
    "1556. Thousand Separator",
    "2653. Sliding Subarray Beauty",
    "1352. Product of the Last K Numbers",
    "2259. Remove Digit From Number to Maximize Result",
    "940. Distinct Subsequences II",
    "16. 3Sum Closest",
    "179. Largest Number",
    "2742. Painting the Walls",
    "166. Fraction to Recurring Decimal",
    "3256. Maximum Value Sum by Placing Three Rooks I",
    "1372. Longest ZigZag Path in a Binary Tree",
    "1733. Minimum Number of People to Teach",
    "363. Max Sum of Rectangle No Larger Than K",
    "2233. Maximum Product After K Increments",
    "27. Remove Element",
    "1091. Shortest Path in Binary Matrix",
    "1622. Fancy Sequence",
    "1439. Find the Kth Smallest Sum of a Matrix With Sorted Rows",
    "2787. Ways to Express an Integer as Sum of Powers",
    "1252. Cells with Odd Values in a Matrix",
    "1846. Maximum Element After Decreasing and Rearranging",
    "897. Increasing Order Search Tree",
    "2499. Minimum Total Cost to Make Arrays Unequal",
    "51. N-Queens",
    "1636. Sort Array by Increasing Frequency",
    "1072. Flip Columns For Maximum Number of Equal Rows",
    "2608. Shortest Cycle in a Graph",
    "42. Trapping Rain Water",
    "306. Additive Number",
    "2487. Remove Nodes From Linked List",
    "397. Integer Replacement",
    "3020. Find the Maximum Number of Elements in Subset",
    "1801. Number of Orders in the Backlog",
    "2593. Find Score of an Array After Marking All Elements",
    "3249. Count the Number of Good Nodes",
    "421. Maximum XOR of Two Numbers in an Array",
    "2828. Check if a String Is an Acronym of Words",
    "2513. Minimize the Maximum of Two Arrays",
    "1649. Create Sorted Array through Instructions",
    "2615. Sum of Distances",
    "1480. Running Sum of 1d Array",
    "1446. Consecutive Characters",
    "1943. Describe the Painting",
    "1502. Can Make Arithmetic Progression From Sequence",
    "2763. Sum of Imbalance Numbers of All Subarrays",
    "69. Sqrt(x)",
    "1541. Minimum Insertions to Balance a Parentheses String",
    "2133. Check if Every Row and Column Contains All Numbers",
    "1572. Matrix Diagonal Sum",
    "86. Partition List",
    "944. Delete Columns to Make Sorted",
    "88. Merge Sorted Array",
    "3047. Find the Largest Area of Square Inside Two Rectangles",
    "1653. Minimum Deletions to Make String Balanced",
    "1388. Pizza With 3n Slices",
    "2490. Circular Sentence",
    "2300. Successful Pairs of Spells and Potions",
    "472. Concatenated Words",
    "1346. Check If N and Its Double Exist",
    "1713. Minimum Operations to Make a Subsequence",
    "3131. Find the Integer Added to Array I",
    "883. Projection Area of 3D Shapes",
    "147. Insertion Sort List",
    "830. Positions of Large Groups",
    "1948. Delete Duplicate Folders in System",
    "2368. Reachable Nodes With Restrictions",
    "1849. Splitting a String Into Descending Consecutive Values",
    "2673. Make Costs of Paths Equal in a Binary Tree",
    "2432. The Employee That Worked on the Longest Task",
    "1878. Get Biggest Three Rhombus Sums in a Grid",
    "1262. Greatest Sum Divisible by Three",
    "2923. Find Champion I",
    "2937. Make Three Strings Equal",
    "2443. Sum of Number and Its Reverse",
    "1367. Linked List in Binary Tree",
    "923. 3Sum With Multiplicity",
    "139. Word Break",
    "679. 24 Game",
    "2718. Sum of Matrix After Queries",
    "2037. Minimum Number of Moves to Seat Everyone",
    "875. Koko Eating Bananas",
    "2134. Minimum Swaps to Group All 1's Together II",
    "232. Implement Queue using Stacks",
    "2862. Maximum Element-Sum of a Complete Subset of Indices",
    "113. Path Sum II",
    "2246. Longest Path With Different Adjacent Characters",
    "2672. Number of Adjacent Elements With the Same Color",
    "461. Hamming Distance",
    "73. Set Matrix Zeroes",
    "2609. Find the Longest Balanced Substring of a Binary String",
    "2201. Count Artifacts That Can Be Extracted",
    "1827. Minimum Operations to Make the Array Increasing",
    "226. Invert Binary Tree",
    "952. Largest Component Size by Common Factor",
    "1463. Cherry Pickup II",
    "538. Convert BST to Greater Tree",
    "1356. Sort Integers by The Number of 1 Bits",
    "894. All Possible Full Binary Trees",
    "2549. Count Distinct Numbers on Board",
    "1928. Minimum Cost to Reach Destination in Time",
    "1209. Remove All Adjacent Duplicates in String II",
    "486. Predict the Winner",
    "355. Design Twitter",
    "3261. Count Substrings That Satisfy K-Constraint II",
    "1845. Seat Reservation Manager",
    "316. Remove Duplicate Letters",
    "643. Maximum Average Subarray I",
    "722. Remove Comments",
    "1751. Maximum Number of Events That Can Be Attended II",
    "2766. Relocate Marbles",
    "854. K-Similar Strings",
    "132. Palindrome Partitioning II",
    "500. Keyboard Row",
    "497. Random Point in Non-overlapping Rectangles",
    "1539. Kth Missing Positive Number",
    "863. All Nodes Distance K in Binary Tree",
    "1467. Probability of a Two Boxes Having The Same Number of Distinct Balls",
    "3207. Maximum Points After Enemy Battles",
    "483. Smallest Good Base",
    "1797. Design Authentication Manager",
    "2194. Cells in a Range on an Excel Sheet",
    "326. Power of Three",
    "2951. Find the Peaks",
    "434. Number of Segments in a String",
    "1977. Number of Ways to Separate Numbers",
    "2089. Find Target Indices After Sorting Array",
    "1773. Count Items Matching a Rule",
    "3072. Distribute Elements Into Two Arrays II",
    "960. Delete Columns to Make Sorted III",
    "1745. Palindrome Partitioning IV",
    "958. Check Completeness of a Binary Tree",
    "1203. Sort Items by Groups Respecting Dependencies",
    "841. Keys and Rooms",
    "1106. Parsing A Boolean Expression",
    "1046. Last Stone Weight",
    "2484. Count Palindromic Subsequences",
    "804. Unique Morse Code Words",
    "318. Maximum Product of Word Lengths",
    "784. Letter Case Permutation",
    "2538. Difference Between Maximum and Minimum Price Sum",
    "278. First Bad Version",
    "464. Can I Win",
    "414. Third Maximum Number",
    "275. H-Index II",
    "1374. Generate a String With Characters That Have Odd Counts",
    "2606. Find the Substring With Maximum Cost",
    "1325. Delete Leaves With a Given Value",
    "1032. Stream of Characters",
    "2423. Remove Letter To Equalize Frequency",
    "988. Smallest String Starting From Leaf",
    "2412. Minimum Money Required Before Transactions",
    "2216. Minimum Deletions to Make Array Beautiful",
    "1968. Array With Elements Not Equal to Average of Neighbors",
    "1177. Can Make Palindrome from Substring",
    "403. Frog Jump",
    "1220. Count Vowels Permutation",
    "168. Excel Sheet Column Title",
    "1936. Add Minimum Number of Rungs",
    "1869. Longer Contiguous Segments of Ones than Zeros",
    "3154. Find Number of Ways to Reach the K-th Stair",
    "2896. Apply Operations to Make Two Strings Equal",
    "2771. Longest Non-decreasing Subarray From Two Arrays",
    "2975. Maximum Square Area by Removing Fences From a Field",
    "1031. Maximum Sum of Two Non-Overlapping Subarrays",
    "2998. Minimum Number of Operations to Make X and Y Equal",
    "2717. Semi-Ordered Permutation",
    "2848. Points That Intersect With Cars",
    "55. Jump Game",
    "480. Sliding Window Median",
    "121. Best Time to Buy and Sell Stock",
    "1550. Three Consecutive Odds",
    "667. Beautiful Arrangement II",
    "2048. Next Greater Numerically Balanced Number",
    "1413. Minimum Value to Get Positive Step by Step Sum",
    "2078. Two Furthest Houses With Different Colors",
    "1737. Change Minimum Characters to Satisfy One of Three Conditions",
    "2924. Find Champion II",
    "1003. Check If Word Is Valid After Substitutions",
    "950. Reveal Cards In Increasing Order",
    "210. Course Schedule II",
    "695. Max Area of Island",
    "3121. Count the Number of Special Characters II",
    "1051. Height Checker",
    "2139. Minimum Moves to Reach Target Score",
    "1805. Number of Different Integers in a String",
    "2258. Escape the Spreading Fire",
    "766. Toeplitz Matrix",
    "2475. Number of Unequal Triplets in Array",
    "2145. Count the Hidden Sequences",
    "564. Find the Closest Palindrome",
    "3144. Minimum Substring Partition of Equal Character Frequency",
    "2347. Best Poker Hand",
    "862. Shortest Subarray with Sum at Least K",
    "1662. Check If Two String Arrays are Equivalent",
    "400. Nth Digit",
    "1850. Minimum Adjacent Swaps to Reach the Kth Smallest Number",
    "962. Maximum Width Ramp",
    "1377. Frog Position After T Seconds",
    "780. Reaching Points",
    "2236. Root Equals Sum of Children",
    "1292. Maximum Side Length of a Square with Sum Less than or Equal to Threshold",
    "2556. Disconnect Path in a Binary Matrix by at Most One Flip",
    "1877. Minimize Maximum Pair Sum in Array",
    "1529. Minimum Suffix Flips",
    "3024. Type of Triangle",
    "1537. Get the Maximum Score",
    "963. Minimum Area Rectangle II",
    "2639. Find the Width of Columns of a Grid",
    "1331. Rank Transform of an Array",
    "1248. Count Number of Nice Subarrays",
    "919. Complete Binary Tree Inserter",
    "528. Random Pick with Weight",
    "561. Array Partition",
    "2865. Beautiful Towers I",
    "1812. Determine Color of a Chessboard Square",
    "2525. Categorize Box According to Criteria",
    "2261. K Divisible Elements Subarrays",
    "89. Gray Code",
    "2287. Rearrange Characters to Make Target String",
    "1335. Minimum Difficulty of a Job Schedule",
    "1448. Count Good Nodes in Binary Tree",
    "2811. Check if it is Possible to Split Array",
    "1958. Check if Move is Legal",
    "1620. Coordinate With Maximum Network Quality",
    "1574. Shortest Subarray to be Removed to Make Array Sorted",
    "223. Rectangle Area",
    "2110. Number of Smooth Descent Periods of a Stock",
    "1348. Tweet Counts Per Frequency",
    "2996. Smallest Missing Integer Greater Than Sequential Prefix Sum",
    "2493. Divide Nodes Into the Maximum Number of Groups",
    "292. Nim Game",
    "1255. Maximum Score Words Formed by Letters",
    "1557. Minimum Number of Vertices to Reach All Nodes",
    "2079. Watering Plants",
    "955. Delete Columns to Make Sorted II",
    "3186. Maximum Total Damage With Spell Casting",
    "2779. Maximum Beauty of an Array After Applying Operation",
    "2215. Find the Difference of Two Arrays",
    "2382. Maximum Segment Sum After Removals",
    "1373. Maximum Sum BST in Binary Tree",
    "1652. Defuse the Bomb",
    "1456. Maximum Number of Vowels in a Substring of Given Length",
    "866. Prime Palindrome",
    "1663. Smallest String With A Given Numeric Value",
    "2845. Count of Interesting Subarrays",
    "3161. Block Placement Queries",
    "1980. Find Unique Binary String",
    "1447. Simplified Fractions",
    "3114. Latest Time You Can Obtain After Replacing Characters",
    "1012. Numbers With Repeated Digits",
    "2856. Minimum Array Length After Pair Removals",
    "974. Subarray Sums Divisible by K",
    "1690. Stone Game VII",
    "815. Bus Routes",
    "698. Partition to K Equal Sum Subsets",
    "1298. Maximum Candies You Can Get from Boxes",
    "993. Cousins in Binary Tree",
    "20. Valid Parentheses",
    "3102. Minimize Manhattan Distances",
    "1974. Minimum Time to Type Word Using Special Typewriter",
    "1995. Count Special Quadruplets",
    "2444. Count Subarrays With Fixed Bounds",
    "58. Length of Last Word",
    "2789. Largest Element in an Array after Merge Operations",
    "315. Count of Smaller Numbers After Self",
    "17. Letter Combinations of a Phone Number",
    "1489. Find Critical and Pseudo-Critical Edges in Minimum Spanning Tree",
    "146. LRU Cache",
    "2376. Count Special Integers",
    "906. Super Palindromes",
    "933. Number of Recent Calls",
    "1475. Final Prices With a Special Discount in a Shop",
    "327. Count of Range Sum",
    "1366. Rank Teams by Votes",
    "589. N-ary Tree Preorder Traversal",
    "1333. Filter Restaurants by Vegan-Friendly, Price and Distance",
    "433. Minimum Genetic Mutation",
    "2370. Longest Ideal Subsequence",
    "2223. Sum of Scores of Built Strings",
    "790. Domino and Tromino Tiling",
    "2953. Count Complete Substrings",
    "3108. Minimum Cost Walk in Weighted Graph",
    "2816. Double a Number Represented as a Linked List",
    "2552. Count Increasing Quadruplets",
    "2851. String Transformation",
    "1409. Queries on a Permutation With Key",
    "2225. Find Players With Zero or One Losses",
    "2920. Maximum Points After Collecting Coins From All Nodes",
    "2353. Design a Food Rating System",
    "83. Remove Duplicates from Sorted List",
    "63. Unique Paths II",
    "2916. Subarrays Distinct Element Sum of Squares II",
    "2429. Minimize XOR",
    "2186. Minimum Number of Steps to Make Two Strings Anagram II",
    "1904. The Number of Full Rounds You Have Played",
    "2206. Divide Array Into Equal Pairs",
    "1828. Queries on Number of Points Inside a Circle",
    "2573. Find the String with LCP",
    "2054. Two Best Non-Overlapping Events",
    "617. Merge Two Binary Trees",
    "2410. Maximum Matching of Players With Trainers",
    "2034. Stock Price Fluctuation ",
    "2508. Add Edges to Make Degrees of All Nodes Even",
    "154. Find Minimum in Rotated Sorted Array II",
    "31. Next Permutation",
    "1641. Count Sorted Vowel Strings",
    "68. Text Justification",
    "1006. Clumsy Factorial",
    "2959. Number of Possible Sets of Closing Branches",
    "1598. Crawler Log Folder",
    "23. Merge k Sorted Lists",
    "1392. Longest Happy Prefix",
    "1022. Sum of Root To Leaf Binary Numbers",
    "1139. Largest 1-Bordered Square",
    "1354. Construct Target Array With Multiple Sums",
    "2161. Partition Array According to Given Pivot",
    "344. Reverse String",
    "1431. Kids With the Greatest Number of Candies",
    "30. Substring with Concatenation of All Words",
    "972. Equal Rational Numbers",
    "535. Encode and Decode TinyURL",
    "3153. Sum of Digit Differences of All Pairs",
    "1671. Minimum Number of Removals to Make Mountain Array",
    "2191. Sort the Jumbled Numbers",
    "2193. Minimum Number of Moves to Make Palindrome",
    "2008. Maximum Earnings From Taxi",
    "189. Rotate Array",
    "1684. Count the Number of Consistent Strings",
    "15. 3Sum",
    "1754. Largest Merge Of Two Strings",
    "386. Lexicographical Numbers",
    "1028. Recover a Tree From Preorder Traversal",
    "2902. Count of Sub-Multisets With Bounded Sum",
    "3044. Most Frequent Prime",
    "924. Minimize Malware Spread",
    "1559. Detect Cycles in 2D Grid",
    "591. Tag Validator",
    "2100. Find Good Days to Rob the Bank",
    "3083. Existence of a Substring in a String and Its Reverse",
    "714. Best Time to Buy and Sell Stock with Transaction Fee",
    "2497. Maximum Star Sum of a Graph",
    "2554. Maximum Number of Integers to Choose From a Range I",
    "2735. Collecting Chocolates",
    "435. Non-overlapping Intervals",
    "2657. Find the Prefix Common Array of Two Arrays",
    "1582. Special Positions in a Binary Matrix",
    "402. Remove K Digits",
    "1288. Remove Covered Intervals",
    "2834. Find the Minimum Possible Sum of a Beautiful Array",
    "855. Exam Room",
    "1903. Largest Odd Number in String",
    "57. Insert Interval",
    "2299. Strong Password Checker II",
    "3149. Find the Minimum Cost Array Permutation",
    "2607. Make K-Subarray Sums Equal",
    "1337. The K Weakest Rows in a Matrix",
    "1525. Number of Good Ways to Split a String",
    "2506. Count Pairs Of Similar Strings",
    "133. Clone Graph",
    "1674. Minimum Moves to Make Array Complementary",
    "1283. Find the Smallest Divisor Given a Threshold",
    "2580. Count Ways to Group Overlapping Ranges",
    "1017. Convert to Base -2",
    "2933. High-Access Employees",
    "1816. Truncate Sentence",
    "748. Shortest Completing Word",
    "1898. Maximum Number of Removable Characters",
    "1700. Number of Students Unable to Eat Lunch",
    "2190. Most Frequent Number Following Key In an Array",
    "518. Coin Change II",
    "937. Reorder Data in Log Files",
    "2974. Minimum Number Game",
    "902. Numbers At Most N Given Digit Set",
    "1503. Last Moment Before All Ants Fall Out of a Plank",
    "2273. Find Resultant Array After Removing Anagrams",
    "2102. Sequentially Ordinal Rank Tracker",
    "2025. Maximum Number of Ways to Partition an Array",
    "2585. Number of Ways to Earn Points",
    "2448. Minimum Cost to Make Array Equal",
    "1871. Jump Game VII",
    "2932. Maximum Strong Pair XOR I",
    "456. 132 Pattern",
    "1036. Escape a Large Maze",
    "1927. Sum Game",
    "1749. Maximum Absolute Sum of Any Subarray",
    "838. Push Dominoes",
    "208. Implement Trie (Prefix Tree)",
    "884. Uncommon Words from Two Sentences",
    "479. Largest Palindrome Product",
    "890. Find and Replace Pattern",
    "718. Maximum Length of Repeated Subarray",
    "2507. Smallest Value After Replacing With Sum of Prime Factors",
    "165. Compare Version Numbers",
    "3133. Minimum Array End",
    "965. Univalued Binary Tree",
    "805. Split Array With Same Average",
    "2591. Distribute Money to Maximum Children",
    "801. Minimum Swaps To Make Sequences Increasing",
    "879. Profitable Schemes",
    "665. Non-decreasing Array",
    "213. House Robber II",
    "2351. First Letter to Appear Twice",
    "2135. Count Words Obtained After Adding a Letter",
    "3191. Minimum Operations to Make Binary Array Elements Equal to One I",
    "3079. Find the Sum of Encrypted Integers",
    "1389. Create Target Array in the Given Order",
    "932. Beautiful Array",
    "2544. Alternating Digit Sum",
    "230. Kth Smallest Element in a BST",
    "821. Shortest Distance to a Character",
    "3148. Maximum Difference Score in a Grid",
    "1764. Form Array by Concatenating Subarrays of Another Array",
    "2844. Minimum Operations to Make a Special Number",
    "3033. Modify the Matrix",
    "187. Repeated DNA Sequences",
    "1397. Find All Good Strings",
    "2120. Execution of All Suffix Instructions Staying in a Grid",
    "2617. Minimum Number of Visited Cells in a Grid",
    "2150. Find All Lonely Numbers in the Array",
    "1496. Path Crossing",
    "532. K-diff Pairs in an Array",
    "1052. Grumpy Bookstore Owner",
    "1423. Maximum Points You Can Obtain from Cards",
    "3160. Find the Number of Distinct Colors Among the Balls",
    "3110. Score of a String",
    "1276. Number of Burgers with No Waste of Ingredients",
    "1293. Shortest Path in a Grid with Obstacles Elimination",
    "1953. Maximum Number of Weeks for Which You Can Work",
    "1695. Maximum Erasure Value",
    "1145. Binary Tree Coloring Game",
    "3129. Find All Possible Stable Binary Arrays I",
    "1562. Find Latest Group of Size M",
    "1310. XOR Queries of a Subarray",
    "1190. Reverse Substrings Between Each Pair of Parentheses",
    "2659. Make Array Empty",
    "409. Longest Palindrome",
    "842. Split Array into Fibonacci Sequence",
    "3005. Count Elements With Maximum Frequency",
    "747. Largest Number At Least Twice of Others",
    "802. Find Eventual Safe States",
    "206. Reverse Linked List",
    "851. Loud and Rich",
    "2289. Steps to Make Array Non-decreasing",
    "1719. Number Of Ways To Reconstruct A Tree",
    "1697. Checking Existence of Edge Length Limited Paths",
    "1615. Maximal Network Rank",
    "1813. Sentence Similarity III",
    "2055. Plates Between Candles",
    "709. To Lower Case",
    "2060. Check if an Original String Exists Given Two Encoded Strings",
    "372. Super Pow",
    "847. Shortest Path Visiting All Nodes",
    "600. Non-negative Integers without Consecutive Ones",
    "1768. Merge Strings Alternately",
    "2344. Minimum Deletions to Make Array Divisible",
    "3045. Count Prefix and Suffix Pairs II",
    "2706. Buy Two Chocolates",
    "2304. Minimum Path Cost in a Grid",
    "2813. Maximum Elegance of a K-Length Subsequence",
    "2491. Divide Players Into Teams of Equal Skill",
    "867. Transpose Matrix",
    "3219. Minimum Cost for Cutting Cake II",
    "2373. Largest Local Values in a Matrix",
    "1478. Allocate Mailboxes",
    "3041. Maximize Consecutive Elements in an Array After Modification",
    "917. Reverse Only Letters",
    "321. Create Maximum Number",
    "401. Binary Watch",
    "377. Combination Sum IV",
    "2971. Find Polygon With the Largest Perimeter",
    "78. Subsets",
    "1758. Minimum Changes To Make Alternating Binary String",
    "1994. The Number of Good Subsets",
    "1318. Minimum Flips to Make a OR b Equal to c",
    "2574. Left and Right Sum Differences",
    "2577. Minimum Time to Visit a Cell In a Grid",
    "1592. Rearrange Spaces Between Words",
    "969. Pancake Sorting",
    "1365. How Many Numbers Are Smaller Than the Current Number",
    "558. Logical OR of Two Binary Grids Represented as Quad-Trees",
    "1736. Latest Time by Replacing Hidden Digits",
    "868. Binary Gap",
    "2395. Find Subarrays With Equal Sum",
    "2640. Find the Score of All Prefixes of an Array",
    "541. Reverse String II",
    "1387. Sort Integers by The Power Value",
    "1520. Maximum Number of Non-Overlapping Substrings",
    "877. Stone Game",
    "5. Longest Palindromic Substring",
    "1344. Angle Between Hands of a Clock",
    "3209. Number of Subarrays With AND Value of K",
    "237. Delete Node in a Linked List",
    "463. Island Perimeter",
    "852. Peak Index in a Mountain Array",
    "788. Rotated Digits",
    "2167. Minimum Time to Remove All Cars Containing Illegal Goods",
    "2582. Pass the Pillow",
    "3250. Find the Count of Monotonic Pairs I",
    "911. Online Election",
    "1833. Maximum Ice Cream Bars",
    "3255. Find the Power of K-Size Subarrays II",
    "2070. Most Beautiful Item for Each Query",
    "1481. Least Number of Unique Integers after K Removals",
    "3042. Count Prefix and Suffix Pairs I",
    "1090. Largest Values From Labels",
    "2251. Number of Flowers in Full Bloom",
    "1078. Occurrences After Bigram",
    "825. Friends Of Appropriate Ages",
    "3257. Maximum Value Sum by Placing Three Rooks II",
    "1723. Find Minimum Time to Finish All Jobs",
    "341. Flatten Nested List Iterator",
    "2698. Find the Punishment Number of an Integer",
    "1923. Longest Common Subpath",
    "368. Largest Divisible Subset",
    "1889. Minimum Space Wasted From Packaging",
    "2708. Maximum Strength of a Group",
    "488. Zuma Game",
    "2682. Find the Losers of the Circular Game",
    "239. Sliding Window Maximum",
    "390. Elimination Game",
    "3177. Find the Maximum Length of a Good Subsequence II",
    "3085. Minimum Deletions to Make String K-Special",
    "609. Find Duplicate File in System",
    "1782. Count Pairs Of Nodes",
    "1010. Pairs of Songs With Total Durations Divisible by 60",
    "1472. Design Browser History",
    "284. Peeking Iterator",
    "3137. Minimum Number of Operations to Make Word K-Periodic",
    "3168. Minimum Number of Chairs in a Waiting Room",
    "1722. Minimize Hamming Distance After Swap Operations",
    "3147. Taking Maximum Energy From the Mystic Dungeon",
    "1599. Maximum Profit of Operating a Centennial Wheel",
    "2240. Number of Ways to Buy Pens and Pencils",
    "2381. Shifting Letters II",
    "2583. Kth Largest Sum in a Binary Tree",
    "936. Stamping The Sequence",
    "2537. Count the Number of Good Subarrays",
    "3159. Find Occurrences of an Element in an Array",
    "219. Contains Duplicate II",
    "771. Jewels and Stones",
    "2002. Maximum Product of the Length of Two Palindromic Subsequences",
    "2022. Convert 1D Array Into 2D Array",
    "32. Longest Valid Parentheses",
    "2209. Minimum White Tiles After Covering With Carpets",
    "688. Knight Probability in Chessboard",
    "1266. Minimum Time Visiting All Points",
    "67. Add Binary",
    "2385. Amount of Time for Binary Tree to Be Infected",
    "1568. Minimum Number of Days to Disconnect Island",
    "2651. Calculate Delayed Arrival Time",
    "310. Minimum Height Trees",
    "3136. Valid Word",
    "864. Shortest Path to Get All Keys",
    "2910. Minimum Number of Groups to Create a Valid Assignment",
    "2305. Fair Distribution of Cookies",
    "2870. Minimum Number of Operations to Make Array Empty",
    "1460. Make Two Arrays Equal by Reversing Subarrays",
    "590. N-ary Tree Postorder Traversal",
    "2654. Minimum Number of Operations to Make All Array Elements Equal to 1",
    "29. Divide Two Integers",
    "436. Find Right Interval",
    "1946. Largest Number After Mutating Substring",
    "1761. Minimum Degree of a Connected Trio in a Graph",
    "1261. Find Elements in a Contaminated Binary Tree",
    "2180. Count Integers With Even Digit Sum",
    "458. Poor Pigs",
    "2678. Number of Senior Citizens",
    "1380. Lucky Numbers in a Matrix",
    "3070. Count Submatrices with Top-Left Element and Sum Less Than k",
    "2746. Decremental String Concatenation",
    "2406. Divide Intervals Into Minimum Number of Groups",
    "820. Short Encoding of Words",
    "1507. Reformat Date",
    "803. Bricks Falling When Hit",
    "502. IPO",
    "357. Count Numbers with Unique Digits",
    "404. Sum of Left Leaves",
    "575. Distribute Candies",
    "1822. Sign of the Product of an Array",
    "1911. Maximum Alternating Subsequence Sum",
    "1227. Airplane Seat Assignment Probability",
    "2103. Rings and Rods",
    "2483. Minimum Penalty for a Shop",
    "689. Maximum Sum of 3 Non-Overlapping Subarrays",
    "76. Minimum Window Substring",
    "810. Chalkboard XOR Game",
    "690. Employee Importance",
    "1315. Sum of Nodes with Even-Valued Grandparent",
    "3178. Find the Child Who Has the Ball After K Seconds",
    "553. Optimal Division",
    "1986. Minimum Number of Work Sessions to Finish the Tasks",
    "2306. Naming a Company",
    "741. Cherry Pickup",
    "2532. Time to Cross a Bridge",
    "697. Degree of an Array",
    "228. Summary Ranges",
    "64. Minimum Path Sum",
    "1415. The k-th Lexicographical String of All Happy Strings of Length n",
    "2419. Longest Subarray With Maximum Bitwise AND",
    "3194. Minimum Average of Smallest and Largest Elements",
    "2855. Minimum Right Shifts to Sort the Array",
    "2115. Find All Possible Recipes from Given Supplies",
    "2352. Equal Row and Column Pairs",
    "1217. Minimum Cost to Move Chips to The Same Position",
    "212. Word Search II",
    "352. Data Stream as Disjoint Intervals",
    "3013. Divide an Array Into Subarrays With Minimum Cost II",
    "2562. Find the Array Concatenation Value",
    "1013. Partition Array Into Three Parts With Equal Sum",
    "3163. String Compression III",
    "1040. Moving Stones Until Consecutive II",
    "813. Largest Sum of Averages",
    "2096. Step-By-Step Directions From a Binary Tree Node to Another",
    "704. Binary Search",
    "2550. Count Collisions of Monkeys on a Polygon",
    "895. Maximum Frequency Stack",
    "930. Binary Subarrays With Sum",
    "552. Student Attendance Record II",
    "840. Magic Squares In Grid"].sort();

    // iterate through problems and extract the number before . and add to the list
   const numbers = [];
   for (let i = 0; i < problems.length; i++) {
       let problem = problems[i];
       let n = problem.split(".")[0];
       numbers.push(n);
   }

  // return (
  //     <div>
  //           <Container>
  //         <Row>
  //         <Col>

  //         <iframe src="https://algoadvance.github.io/algoadvance/l1-two-sum" width="100%" height="1000px"></iframe>
  // </Col>
  //         </Row>
  //     </Container>
  //     </div>
  // )

  const googleUrl = "https://algoadvance.github.io/algoadvance";

  const refBook = useRef(null);
  const refIframe = useRef(null);
  const [iframSrc, setIframeUrl] = useState(googleUrl);
  const [iframSolSrc, setIframeSolUrl] = useState(googleUrl);
  const [iframeKey, setIframeKey] = useState("l0");
  const [iframeSolKey, setIframeSolKey] = useState("s0");

  
  const getNumberAndGoBook = (problem) => {
    let number = problem.split(".")[0];
    setNumber(number);
    setIframeUrl(
      `${googleUrl}/l${number}`
    );
    setIframeSolUrl(
      `${googleUrl}/s${number}`
    );
  };

  useEffect(() => {
     
   // if numbers contain number, then set IFramUrl to that number
   if (numbers.includes(number)) {
       console.log("yes");
       setIframeUrl(
           `${googleUrl}/l${number}`
       );
       setIframeKey("l"+number);
       setIframeSolUrl(
         `${googleUrl}/s${number}`
       );
       setIframeSolKey("s"+number);
   }   

  }, [numbers, number]);

  return (
    <div >
      <Container class="container-fluid">
        <Row class="container-fluid">
          <input type="text" ref={refBook}></input>
          {/* <input type="number" ref={refPage}></input> */}
          {/* <button type="button" onClick={goBook}>
        Submit
      </button> */}
          <Col sm="3"> <ListGroup >

            {problems.map((problem, index) => (
              <ListGroupItem key={index} onClick={() => {
                refBook.current.value = problem;
                getNumberAndGoBook(problem);
              }}>{problem}</ListGroupItem>
            ))}
          </ListGroup>
          </Col>
          <Col>
            <iframe
              key = {iframeKey}
              title="problem"
              src={iframSrc}
              height="500"
              width="100%"
              frameBorder="0"
              scrolling="yes"
              allowFullScreen={true}
              ref={refIframe}
            ></iframe>
            <h1>Solution</h1>
            <iframe
              title="solution"
              key = {iframeSolKey}
              src={iframSolSrc}
              height="720"
              width="100%"
              frameBorder="0"
              scrolling="yes"
              allowFullScreen={true}
              ref={refIframe}
            ></iframe> </Col>
        </Row>
      </Container>
    </div>

  );
}

export default Problems