// import TreeMap from "react-d3-treemap";
import React from "react";

import {
    Container,
    Row,
    Col,

  } from "reactstrap";

  const Map  = () => {
    // let data = {
    //   name: "Technology",
    //   children: [
    //     { name: "AAPL.US", value: 34.76, children: [] },
    //     { name: "MSFT.US", value: 31.71, children: [] },
    //     { name: "NVDA.US", value: 13.24, children: [] },
    //     { name: "AVGO.US", value: 5.42, children: [] },
    //     { name: "ADBE.US", value: 3.19, children: [] },
    //     { name: "ASML.US", value: 3.18, children: [] },
    //     { name: "AMD.US", value: 2.41, children: [] },
    //     { name: "CSCO.US", value: 2.26, children: [] },
    //     { name: "INTC.US", value: 2.0, children: [] },
    //     { name: "INTU.US", value: 1.84, children: [] },
    //   ],
    // };
    // return (
    //     <div>
    //             <TreeMap
    //       nodeStyle={{
    //         fontSize: 12,
    //         paddingLeft: 10,
    //         stroke: "transparent !important",
    //         alignSelf: "center !important",
    //         alignContent: "center !important",
    //       }}
    //       data={data}
    //     />
    //     </div>
    // )
}

export default Map