
import {
    Container,
    Row,
    Col,

  } from "reactstrap";
  import ReactMarkdown from 'react-markdown'

  const TermsOfService  = () => {
    
  const markdown = `
  ### Terms of Service for AlgoAdvance.com

**Last Updated:  7/13/2024**

Welcome to AlgoAdvance.com! By accessing or using our website, services, software, or any associated content (collectively, "Services"), you agree to be bound by these Terms of Service ("Agreement"). If you do not agree to this Agreement, do not use our Services.

#### 1. **Usage Eligibility**
- You must be at least 18 years old to use our Services, or if you are under 18, you must have your parent or guardian’s permission.
- You must provide accurate and complete registration information any time you register to use our Services.

#### 2. **License to Use**
- Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to use our Services for your personal, non-commercial use.

#### 3. **User Accounts**
- You are responsible for safeguarding the password that you use to access any secure areas of the Services. We encourage you to use "strong" passwords (passwords that use a combination of upper and lower case letters, numbers, and symbols).
- You are responsible for all activities under your account.

#### 4. **Prohibited Activities**
- You may not access or use the Services for any purpose other than that for which we make the Services available.
- You may not engage in any activity that interferes with or disrupts the Services.
- Unauthorized use of our Services or misuse of any information posted to the site is strictly prohibited.

#### 5. **Intellectual Property Rights**
- The content on AlgoAdvance.com, including text, graphics, images, software, and other material is the property of AlgoAdvance and is protected by copyright and other intellectual property laws.
- You may not distribute, modify, transmit, reuse, download, repost, copy, or use said content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.

#### 6. **Subscription Fees and Payment**
- AlgoAdvance offers various subscription plans. Fees for our services are subject to change and may be updated from time to time.
- All fees are payable in advance and are not refundable, except as otherwise provided in these terms.

#### 7. **Termination and Suspension**
- We may terminate or suspend your access to our Services immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach these Terms.
- All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.

#### 8. **Disclaimers**
- Our Services are provided on an "as is" and "as available" basis. AlgoAdvance does not warrant that the service will be uninterrupted, secure, or free from errors.
- Your use of the Services is at your sole risk.

#### 9. **Limitation of Liability**
- In no event shall AlgoAdvance, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your access to or use of, or inability to access or use the Services.

#### 10. **Changes to Terms**
- We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.

#### 11. **Governing Law**
- These Terms shall be governed and construed in accordance with the laws of State of New York, without regard to its conflict of law provisions.

`

    return (
        <div>
              <Container>
            <Row>
            <Col>
            <ReactMarkdown>
      {markdown}
    
                </ReactMarkdown>
                
   
    </Col>
            </Row>
        </Container>
        </div>
    )
}

export default TermsOfService