import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyDPaS7k50aSra3LXxvqEqbaydz_smquE0E",
  authDomain: "handwritten-852e8.firebaseapp.com",
  projectId: "handwritten-852e8",
  storageBucket: "handwritten-852e8.appspot.com",
  messagingSenderId: "667637206925",
  appId: "1:667637206925:web:843bdfa9c236b41eb9b7c4",
  measurementId: "G-9BMK6681G6"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { app, auth, db ,storage};
