import { db } from '../../firebase/firebase'
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuth } from '../../contexts/authContext'
import { collection, onSnapshot, getDocs, query, where } from "firebase/firestore";
import {
    Container,
    Row,
    Col,
    Button,
    Spinner
  } from "reactstrap";
  import { useState, useEffect } from 'react'

  const Profile = () => {
    const [subscriptions, setSubscriptions] = useState();
    const [price, setPrice] = useState();
    const [product, setProduct] = useState();
    const [processing, setProcessing] = useState(false);

    const [promo, setPromo] = useState();
    // const [subscriptions, setSubscriptions] = useState();
    const { currentUser } = useAuth()
    const subscriptionCollectionRef = collection(db, "customers", currentUser.uid, "subscriptions");
    const activeSubscriptionRef = query(subscriptionCollectionRef, where('status', 'in', ['trialing', 'active']))

    const profileCollectionRef = collection(db, "profile_by_uid", currentUser.uid, "promo_codes");

  
    useEffect(()=>{
        const getPromo = async () => {
            const data = await getDocs(profileCollectionRef)
            if (data && data.docs && data.docs.length > 0) {
                    console.log(data.docs)
                    setPromo(data.docs.map((elem) => ({ ...elem.data(), id: elem.id })))
                }
       }

       const getSubscription = async () => {
        const data = await getDocs(activeSubscriptionRef)
        if (data && data.docs && data.docs.length > 0) {
                   setSubscriptions(data.docs.map((elem) => ({ ...elem.data(), id: elem.id })))
                let priceRef = data.docs[0].data().price
                   onSnapshot(priceRef, (snapshot) => {
                    // console.log(snapshot.data());          
                    setPrice(snapshot.data())  });
                let productRef = data.docs[0].data().product
                onSnapshot(productRef, (snapshot) => {
                    // console.log(snapshot.data());          
                    setProduct(snapshot.data())  });
            }
   }
       getSubscription()
       getPromo()
       console.log(subscriptions)
       console.log(subscriptions && subscriptions[0].current_period_start)
    },[])

    const accessClientPortal = async() => {
        setProcessing(true);
        const functions = getFunctions();
        const functionRef = httpsCallable(functions,'ext-firestore-stripe-payments-createPortalLink');
const { data } = await functionRef({
  returnUrl: window.location.origin,
  locale: "auto", // Optional, defaults to "auto"
  //configuration: "bIYeXfehy2pe8Mg7ss", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
//   https://billing.stripe.com/p/login/bIYeXfehy2pe8Mg7ss
});
window.location.assign(data.url);

    }


    return (
        <div>
              <Container>
            <Row>
            <Col>
            <div>
                {promo && <h3>My promo codes</h3>}
                {promo && promo.map((p) => (
                    <div key={p.id}>
                        <p>Promo Code: {p.code}</p>
                        {/* <p>Active: {p.valid}</p> */}
                        <p>Times Redeemed: {p.times_redeemed}</p>
                        <hr></hr>
                    </div>
                ))}
             
                </div>
            <h3>My subscription</h3>
            <div>
                {subscriptions && subscriptions.map((subscription) => (
                    <div key={subscription.id}>
                        {/* <p>Subscription ID: {subscription.id}</p> */}
                        <p>Plan: {product && product.name}</p>
                        <p>Status: {subscription.status}</p>
                        <p>Current period start: {subscription.current_period_start && new Date(subscription.current_period_start.toDate()).toLocaleDateString()}</p>
                        <p>Current period end: {subscription.current_period_end && new Date(subscription.current_period_end.toDate()).toLocaleDateString()}</p>
                        <p>Amount: {price && (price.unit_amount / 100).toFixed(2)} {price && price.currency}</p>
                        <p>Payment Type: {price && price.type} {price && price.interval}ly</p>
                        <hr></hr>
                    </div>
                    
                ))}
            </div>
           
            <h4>View invoices, update subscription & payment methods</h4>
            <Button onClick={accessClientPortal}>
            Access customer portal 
            {processing && 
      <Spinner
      size="sm"
      type="border"
    />}
    </Button>
   
    </Col>
            </Row>
        </Container>
        </div>
    )
}

export default Profile