import { ReactGrid, Column, Row, CellChange, NumberCell } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import React, { useEffect, useState } from "react";
import { db } from '../../firebase/firebase';
import { addDoc, collection, serverTimestamp, onSnapshot, query, where, orderBy, limit, updateDoc, doc } from "firebase/firestore";
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const getColumns = () => [
  { columnId: "high", width: 100 },
  { columnId: "low", width: 100 },
  { columnId: "timeFrame", width: 100 },
  { columnId: "score1", width: 100 },
  { columnId: "score2", width: 100 },
  { columnId: "scoreLink", width: 200 },
  { columnId: "chartLink", width: 200 },
  { columnId: "order_placed", width: 100 },
  { columnId: "use_ttl", width: 100 },
  { columnId: "breakout_price", width: 100 }
];

const headerRow = {
  rowId: "header",
  cells: [
    { type: "header", text: "High" },
    { type: "header", text: "Low" },
    { type: "header", text: "Time Frame" },
    { type: "header", text: "Score 1" },
    { type: "header", text: "Score 2" },
    { type: "header", text: "Score Link" },
    { type: "header", text: "Chart Link" },
    { type: "header", text: "Order Placed" },
    { type: "header", text: "use TTL" },
    { type: "header", text: "Breakout Price" }
  ]
};

const getRows = (data) => [
  headerRow,
  ...data.map((item, idx) => ({
    rowId: idx,
    cells: [
      { type: "number", value: item.high },
      { type: "number", value: item.low },
      { type: "number", value: item.timeFrame },
      { type: "number", value: item.score1 },
      { type: "number", value: item.score2 },
      { type: "text", text: item.scoreLink || "" },
      { type: "text", text: item.chartLink || "" },
      { type: "text", text: item.order_placed ? "True" : "False" },
      { type: "text", text: item.use_ttl ? "True" : "False" },
      { type: "number", value: item.breakout_price }
    ]
  }))
];

const applyChangesToData = (
  changes,
  prevData
) => {
  changes.forEach((change) => {
    const dataIndex = change.rowId;
    const fieldName = change.columnId;
    if (fieldName === "scoreLink" || fieldName === "chartLink" || fieldName === "order_placed" || fieldName === "use_ttl") {
      prevData[dataIndex][fieldName] = change.newCell.text;
    } else {
    prevData[dataIndex][fieldName] = change.newCell.value;
    }
  });
  return [...prevData];
};

const Grid = () => {
  const [data, setData] = useState([]);
  const [demandData, setDemandData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedPath, setSelectedPath] = useState("MES");

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    const entriesCollectionRef = collection(db, "grid", selectedPath, "supply");
    const recentActiveRef = query(entriesCollectionRef, orderBy("time", "desc"), limit(10));
    const unsubscribe = onSnapshot(recentActiveRef, (snapshot) => {
      const newData = snapshot.docs.map((doc) => {
        let data = doc.data();
        // console.info(data);
        return {
          id: doc.id,
          high: data.high,
          low: data.low,
          timeFrame: data.timeFrame,
          score1: data.score1,
          score2: data.score2,
          scoreLink: data.scoreLink,
          chartLink: data.chartLink,
          order_placed: data.order_placed || false,
          use_ttl : data.use_ttl || false,
          breakout_price: data.breakout_price || 0
        };
      });
      if (newData.length === 0) {
        setData([{ high: 0, low: 0, timeFrame: 0, score1: 0, score2: 0, scoreLink: "", chartLink: "",  order_placed: false , use_ttl: false, breakout_price: 0}]);
      } else {
        setData(newData);
      }
    });

    return () => unsubscribe();
  }, [selectedPath]);

  useEffect(() => {
    const demandCollectionRef = collection(db, "grid", selectedPath, "demand");
    const recentActiveRef = query(demandCollectionRef, orderBy("time", "desc"), limit(10));
    const unsubscribe = onSnapshot(recentActiveRef, (snapshot) => {
      const newDemandData = snapshot.docs.map((doc) => {
        let data = doc.data();
        //  console.info(data);
        return {
          id: doc.id,
          high: data.high,
          low: data.low,
          timeFrame: data.timeFrame,
          score1: data.score1,
          score2: data.score2,
          scoreLink: data.scoreLink,
          chartLink: data.chartLink,
          order_placed: data.order_placed || false,
          use_ttl: data.use_ttl || false,
          breakout_price: data.breakout_price || 0
        };
      });
      if (newDemandData.length === 0) {
        setDemandData([{ high: 0, low: 0, timeFrame: 0, score1: 0, score2: 0 ,  scoreLink: "", chartLink: "",  order_placed: false , use_ttl: false, breakout_price: 0 }]);
      } else {
        setDemandData(newDemandData);
      }
    });

    return () => unsubscribe();
  }, [selectedPath]);

  const supplyRows = getRows(data);
  const demandRows = getRows(demandData);
  const columns = getColumns();

  const handleChanges = (changes, setDataFn) => { 
    setDataFn((prevData) => applyChangesToData(changes, prevData)); 
  }; 

  const addEmptyRow = (setDataFn) => {
    setDataFn((prevData) => [...prevData, { high: 0, low: 0, timeFrame: 0, score1: 0, score2: 0 , scoreLink: "", chartLink: "" }]);
  };

  const saveDataToFirebase = async (data, path) => {
    try {
      for (const item of data) {
        if (item.id) {
          const docRef = doc(db, `/grid/${selectedPath}/${path}`, item.id);
          console.log(item);
          console.log(item.use_ttl, item.use_ttl === "True", item.use_ttl == true)
          await updateDoc(docRef, {
            high: item.high,
            low: item.low,
            timeFrame: item.timeFrame,
            score1: item.score1,
            score2: item.score2,
            scoreLink: item.scoreLink || "" ,
            chartLink: item.chartLink || "" ,
            order_placed: item.order_placed === "True",
            use_ttl: item.use_ttl === "True" || item.use_ttl,
            breakout_price: item.breakout_price || 0,
            updateTime: serverTimestamp()
          });
        } else {
          console.log(item);
          console.log(item.use_ttl, item.use_ttl === "True", item.use_ttl == true)
          await addDoc(collection(db, `/grid/${selectedPath}/${path}`), {
            high: item.high,
            low: item.low,
            timeFrame: item.timeFrame,
            score1: item.score1,
            score2: item.score2,
            scoreLink: item.scoreLink || "" ,
            chartLink: item.chartLink || "" ,
            order_placed: item.order_placed === "True",
            use_ttl: item.use_ttl === "True"  ||  item.use_ttl=== undefined ? false :item.use_ttl,
            breakout_price: item.breakout_price || 0,
            time: serverTimestamp()
          });
        }
      }
    } catch (error) {
      console.error(`Unable to save data to Firebase for ${path}.`, error);
    }
  };

  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} style={{ marginBottom: '10px' }}>
        <DropdownToggle caret>
          {selectedPath}
        </DropdownToggle>
        <DropdownMenu>
          {['MES', 'MNQ', 'M2K', 'MYM', 'MCL', 'MGC', 'MBT', 'M6A', 'M6E', 'MHG', 'MNG', 'QI', 'NG'].map((item) => (
            <DropdownItem key={item} onClick={() => setSelectedPath(item)}>
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>

      <div style={{ marginBottom: '20px' }}>
        <h3 style={{ color: 'red' }}>Supply Zone</h3>
        <ReactGrid rows={supplyRows} columns={columns} onCellsChanged={(changes) => handleChanges(changes, setData)} enableRowSelection />
        <Button color="info" onClick={() => saveDataToFirebase(data, "supply")}>Save Data</Button>
        <Button color="secondary" onClick={() => addEmptyRow(setData)}>Add Empty Row</Button>
      </div>

      <div>
        <h3 style={{ color: 'green' }}>Demand Zone</h3>
        <ReactGrid rows={demandRows} columns={columns} onCellsChanged={(changes) => handleChanges(changes, setDemandData)} enableRowSelection/>
        <Button color="info" onClick={() => saveDataToFirebase(demandData, "demand")}>Save Data</Button>
        <Button color="secondary" onClick={() => addEmptyRow(setDemandData)}>Add Empty Row</Button>
      </div>
    </div>
  );
};

export default Grid;