import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/authContext'
import { doSignOut } from '../../firebase/auth'
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    NavbarText,
  } from 'reactstrap';
const Header = () => {
    const navigate = useNavigate()
    const { userLoggedIn } = useAuth()
    return (
        <Navbar className='flex flex-row gap-x-2 w-full z-20 fixed top-0 left-0 h-12 border-b place-content-center items-center bg-gray-200'>
             <NavbarBrand > 
             <Link  className='text-black'  to={'/'}>
             <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("../../assets/img/brand/rocket.png")} />
                  AlgoAdvance
        </Link></NavbarBrand>
             <Nav tabs>
             <NavItem>
            
            <NavLink ><Link className='text-sm text-blue-600' to={'/problems'}>Practice Problems</Link></NavLink>
          
   </NavItem>
             {
                userLoggedIn
                    ?
                    <>
             <NavItem>
            
                     <NavLink ><Link className='text-sm text-blue-600' to={'/home'}>Text Capture</Link></NavLink>
                   
            </NavItem>
            <NavItem>            
                    <NavLink ><Link className='text-sm text-blue-600' to={'/screen'}>Screen Capture</Link></NavLink>
                   
            </NavItem>

            </>
             :
             <> </>
            }

            </Nav>
            {/* <Link className='text-sm text-blue-600 underline' to={'/home'}>Workspace</Link> */}
            <NavbarText>
            {
                userLoggedIn
                    ?
                    <>
                         <Link className='text-sm text-blue-600' to={'/profile'}>Profile</Link>
                        {' '}
                        <button onClick={() => { doSignOut().then(() => { navigate('/login') }) }} className='text-sm text-blue-600 underline'>Logout</button>
                    </>
                    :
                    <>
                        <Link className='text-sm text-blue-600 underline' to={'/login'}>Login</Link>
                        {' '}
                        <Link className='text-sm text-blue-600 underline' to={'/register'}>Register</Link>
                    </>
            }
            </NavbarText>

        </Navbar>
    )
}

export default Header