
import {
    Container,
    Row,
    Col,

  } from "reactstrap";
  import ReactMarkdown from 'react-markdown'

  const Privacy  = () => {
    
  const markdown = `
  ### Privacy Policy for AlgoAdvance.com and AlgoAdvance Coding Helper Chrome Extension

  **Last Updated: 07/12/2024**

  This Privacy Policy describes how the AlgoAdvance Coding Helper Chrome Extension (referred to as "we", "us", or "our") collects, uses, and shares information about you when you use our extension installed on your Chrome browser.
  
  #### Information We Collect
  
  **User-Provided Information:**
  - **Account Information:** To use certain features of the extension, you may need to create an account where you provide us with your email address, and other relevant details.
  - **User Data:** We collect data related to the coding problems you work on, including the code you write and your notes.
  
  **Automatically Collected Information:**
  - **Usage Data:** We may collect information about how you use our extension, such as the features you use and the frequency of your sessions.
  - **Device and Connection Information:** We may collect information about your computer, browser type, and other technical details about your device, as well as information about your connection to our services.
  
  **Information from Specific Websites:**
  - We collect text and images that you interact with on a designated list of websites used specifically for coding interviews and preparation. This data is crucial for enhancing your learning experience and providing relevant assistance.
  
  #### How We Use Your Information
  
  - **To Provide Services:** We use your information to operate, maintain, and provide the features and functionality of the extension.
  - **To Improve and Customize:** We use the collected data to understand and analyze the usage trends and preferences of our users, to improve our extension, and to develop new products, services, features, and functionality.
  - **For Communication:** We may use your email address to contact you for administrative purposes, customer service purposes, or regarding content that you have posted to our extension.
  
  #### Sharing of Your Information
  
  - **Legal Compliance:** We may disclose your information if required to do so by law or in the good faith belief that such action is necessary to comply with a legal obligation, protect and defend our rights or property, protect the personal safety of users of the extension, or protect against legal liability.
  - **Business Transfers:** In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your personal information may be among the assets transferred.
  
  #### Security
  
  We take reasonable measures to protect the information we collect from or about you (including your personal information) from unauthorized access, use, or disclosure. However, no internet or email transmission is ever fully secure or error-free.
  
  #### Changes to This Privacy Policy
  
  We may modify this privacy policy from time to time. If we make material changes to how we treat our users' personal information, we will notify you through the extension or by other means such as email.
  `

    return (
        <div>
              <Container>
            <Row>
            <Col>
            <ReactMarkdown>
      {markdown}
    
                </ReactMarkdown>
                
   
    </Col>
            </Row>
        </Container>
        </div>
    )
}

export default Privacy