
import { db } from '../../firebase/firebase'
import { useAuth } from '../../contexts/authContext'
import { addDoc, collection, getDocs, onSnapshot, query, where} from "firebase/firestore";
import { useState, useEffect } from 'react'

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Button,
    Spinner
  } from "reactstrap";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_51PCYNUIbhhsYoqvGDdaYMd5PpN3EefLWajt08saVtPPqUuFxR4cvgoUG8S1ZHTWFmYkpoSTEUse5bTMeiI9thNiT00nx4afghZ');

const Checkout = () => {
    const { currentUser } = useAuth()
    const [products, setProducts] = useState();
    const [prices, setPrices] = useState();
    const productCollectionRef = collection(db, "products");
    const activeProductRef = query(productCollectionRef, where('active', '==', true))
    const [processing, setProcessing] = useState(false);
  
    const trial_period_days_by_price_id = {
        "price_1PX88aIbhhsYoqvGsKAtk0Y0": 0,
        "price_1PWrNNIbhhsYoqvGVq4s3qFZ": 7,
        "price_1PWrMOIbhhsYoqvGM2UpllXj": 0,
    }

    const original_price_by_price_id = {
      "price_1PX88aIbhhsYoqvGsKAtk0Y0": 19.99,
      "price_1PWrNNIbhhsYoqvGVq4s3qFZ": 69.99,
      "price_1PWrMOIbhhsYoqvGM2UpllXj": 599.99,
  }

    useEffect(()=>{
      const getProducts= async () => {
          const data = await getDocs(activeProductRef)
          if (data && data.docs && data.docs.length > 0) {
            setProducts(data.docs.map((elem) => ({ ...elem.data(), id: elem.id })))              
              }
     }
     getProducts()
     console.log(products)
  },[])

  // Checkout handler
const subscribe = async (priceid) =>{
    setProcessing(true);
    let checkoutsessionsRef =  collection(db, "customers", currentUser.uid, "checkout_sessions")
    console.log(priceid)
    let trial_period_days = trial_period_days_by_price_id[priceid]
    const selectedPrice = {
      price:priceid, 
        quantity: 1,
    };
    // For prices with metered billing we need to omit the quantity parameter.
    // For all other prices we set quantity to 1.
    // const checkoutSession = {
    //   automatic_tax: true,
    //   tax_id_collection: true,
    //   collect_shipping_address: true,
    //   allow_promotion_codes: true,
    //   line_items: [selectedPrice],
    //   success_url: window.location.origin,
    //   cancel_url: window.location.origin,
    //   metadata: {
    //     key: 'value',
    //   },
    // };
    try {
        let docRef =  await addDoc(checkoutsessionsRef, {
            // update later
            // automatic_tax: true, 
            // tax_id_collection: true,
            // collect_shipping_address: true,
            allow_promotion_codes: true,
            line_items: [selectedPrice],
            trial_period_days: trial_period_days,
            success_url: window.location.origin+"/profile",
            cancel_url: window.location.origin+"/profile",
            metadata: {
              key: 'value',
            },
            consent_collection: {
              terms_of_service: 'required',
            },
            custom_text: {
              terms_of_service_acceptance: {
                message: 'I agree to the [Terms of Service](https://algoadvance.com/terms-of-service)',
              }
            }
        });

        onSnapshot(docRef, (snapshot) => {
            console.log(snapshot.data());
            let {error, url} = snapshot.data();
            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.replace(url);
  }

        });
        
          
        } catch (error) {
            alert(`An error occured: ${error.message}`);
          console.error(
            "Unable to delete entry.", error
        );
    }

  };
  
  return (
    <div>
        <Container>
            <Row>
           
            {products && products.map((product) => (
               <Col>
            <Card
  style={{
    width: '18rem'
  }}
>
  <img
    alt="Sample"
    src="https://picsum.photos/300/200"
  />
  <CardBody>
    <CardTitle tag="h5">
      {product.name}
    </CardTitle>
    <CardSubtitle
      className="mb-2 text-muted"
      tag="h6"
    >
      $ {product.metadata && product.metadata.price}   <s>{product.metadata && original_price_by_price_id[product.metadata.price_id]}</s>
    </CardSubtitle>
    <CardText>
      {product.description}
      { trial_period_days_by_price_id[product.metadata.price_id] > 0 && 
      <h5 style={{ color: 'red' }}>Includes {trial_period_days_by_price_id[product.metadata.price_id]} day free trial! </h5>}
         </CardText>
    <Button onClick={ subscribe.bind(this, product.metadata.price_id)}>
      Subscribe
      {processing && 
      <Spinner
      size="sm"
      type="border"
    />}
    </Button>
  </CardBody>
</Card>
</Col>
            ))}

           
            </Row>
        </Container>
    </div>
   
  );
};

export default Checkout